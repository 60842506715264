/*=========================================================================================
  File Name: globalComponents.js
  Description: Here you can register components globally
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import VxTooltip from './layouts/components/vx-tooltip/VxTooltip.vue'
import VxCard  from './vx-components/vx-card/VxCard.vue'
import VxList  from './vx-components/vx-list/VxList.vue'
import VxBreadcrumb  from './layouts/components/VxBreadcrumb.vue'
import FeatherIcon  from './vx-components/FeatherIcon.vue'
import VxInputGroup  from './vx-components/vx-input-group/VxInputGroup.vue'
import VxTextArea  from './vx-components/vx-text-area/VxTextArea.vue'
import VxSelect  from './vx-components/vx-select/VxSelect.vue'
import VxSelectItem  from './vx-components/vx-select/VxSelectItem.vue'
import vsTable  from './vx-components/vsTable/vsTable.vue';
import vsCheckBox  from './vx-components/vsCheckBox/vsCheckBox.vue'
import VError  from './components/forms/VError.vue'
import DownloadButton  from './components/supplier/DownloadButton.vue'
import VxTimeline  from './vx-components/timeline/VxTimeline.vue'
import StatisticsCardLine  from './vx-components/statistics-cards/StatisticsCardLine.vue'
import vsSelect from './vx-components/vsSelect/vsSelect';
import vsSelectGroup from './vx-components/vsSelect/vsSelectGroup';
import vsSelectItem from './vx-components/vsSelect/vsSelectItem';
import VueCountryCode from "vue-country-code-select";
import RgRadio from "./components/shared/RgRadio.vue";
import RgGeolocator from "./components/geolocation/RgGeolocator.vue";

Vue.component(VxTooltip.name, VxTooltip)
Vue.component(VxCard.name, VxCard)
Vue.component(VxList.name, VxList)
Vue.component(VxBreadcrumb.name, VxBreadcrumb)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(VxInputGroup.name, VxInputGroup)
Vue.component(VxTextArea.name, VxTextArea)
Vue.component(VxSelect.name, VxSelect)
Vue.component(VxSelectItem.name, VxSelectItem)
Vue.component(VError.name, VError)
Vue.component(DownloadButton.name, DownloadButton)
Vue.component(VxTimeline.name, VxTimeline)
Vue.component(vsTable.name, vsTable)
Vue.component(vsCheckBox.name, vsCheckBox)
Vue.component(vsSelect.name, vsSelect)
Vue.component(vsSelectGroup.name, vsSelectGroup)
Vue.component(vsSelectItem.name, vsSelectItem)
Vue.component(VueCountryCode.name, VueCountryCode)
Vue.component(StatisticsCardLine.name, StatisticsCardLine)
Vue.component(RgRadio.name, RgRadio)
Vue.component(RgGeolocator.name, RgGeolocator)

// v-select component
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
