<!-- =========================================================================================
    File Name: VxTimeline.vue
    Description: Timeline Component
    Component Name: VxTimeline
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div v-if="isCustom || isHorizontal" id="timeline-component">
    <ul v-if="isCustom" class="vx-timeline">
      <li v-for="item in data" :key="item.title">
        <div class="timeline-icon small" :class="`bg-${item.color}`">
          <feather-icon :icon="item.icon" svgClasses="text-white stroke-current w-5 h-5" />
        </div>
        <div class="timeline-info">
          <h5 class="font-semibold">{{ item.title }}</h5>
          <span class="activity-desc">{{ item.desc }}</span>
        </div>
        <small class="text-grey activity-e-time">{{ item.time }}</small>
      </li>
    </ul>

    <div v-if="isHorizontal" class="vx-timeline horizontal">
      <div v-for="(item, index) in data" :key="item.title">
        <div class="timeline-icon small" :class="`bg-${item.color}`">
          <feather-icon :icon="item.icon" svgClasses="stroke-current w-5 h-5" :svgClasses="item.icon_color" />
        </div>
        <div v-if="index > 0" class="line"></div>
        <div class="timeline-info">
          <h6 class="">{{ item.title }}</h6>
          <span class="activity-desc">{{ item.desc }}</span>
        </div>
        <small class="text-grey activity-e-time">{{ item.time }}</small>
      </div>
    </div>

  </div>
  <div v-else>
    <ul class="vx-timeline">
      <li v-for="(item, index) in data" :key="index">
        <div class="timeline-basic" :class="`bg-${item.color}`"></div>
        <div class="timeline-info">
          <div class="vx-row">
            <div class="vx-col w-3/4">
              <h5 class="bold">{{ item.title }}</h5>
              <p class="font-semibold" v-if="item.user != null">{{ item.user }}</p>
              <small style="word-break: break-word;" v-if="item.desc != null">{{ item.desc }}</small>
            </div>
            <div class="vx-col w-1/4">
              <small class="text-grey activity-e-time">{{ item.time }}</small>
            </div>
          </div>
        </div>

      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'vx-timeline',
  props: {
    data: {
      type: Array,
      required: true
    },
    isCustom: {
      type: Boolean
      , default: false
    },
    isHorizontal: {
      type: Boolean
      , default: false
    }
  }
}
</script>

<style lang="scss">
@import "@sass/vuexy/components/vxTimeline.scss";
</style>

<style>
@media only screen and (max-width: 600px) {
  .horizontal {
    display: flex !important;
  }
}

@media only screen and (min-width:636px) and (max-width:768px) {
  .horizontal {
    display: flex !important;
  }
}
</style>

<style lang="scss">
.horizontal {
  display: ruby-text;
  flex-direction: row;
  justify-content: space-between;
  border-left: none;
  padding-left: 0;
  margin-left: 0;

  div {
    position: relative;
    margin-bottom: 20px;

    .timeline-basic {
      position: absolute;
      top: 0;
      left: -3.5rem;
      border-radius: 50%;
      padding: 0.75rem;
      padding-bottom: 0.4rem;
      min-height: 21px;
      min-width: 15px;
    }

    .timeline-icon {
      margin-bottom: -1rem;
      top: 0;
      border-radius: 50%;
      padding: .75rem;
      padding-bottom: 0.4rem;
      margin-left: 5rem;
      margin-right: 5rem;
      z-index: 10;
    }

    .activity-desc {
      font-size: .9rem;
    }

    .activity-e-time {
      font-size: .8rem;
    }

    .timeline-info {
      margin-top: 2rem;
      padding: 0 1rem;
      width: 100%;
    }

    .line {
      border: 1px solid #E5E8EB;
      position: absolute;
      width: 13rem;
      left: -6rem;
      top: 1.3rem;
    }
  }
}
</style>
