export async function get_session_info (context) {
  try {
    let res = await axios.get('/api/v1/users/getRgUserInfo');
    context.commit('set_new_extended_session', res.data.user_id);
    context.commit('set_extended_session_role', res.data.role);
    return res;
  }
  catch(e) {
    throw e;
  }
}