<template lang="html">
  <div    
    :class="[`vs-textarea-${color}`, {
      'textarea-danger': counter ? (value && value.length > counter) : false, 'focusx': isFocus,
      'textarea-success':success,
      'textarea-danger':danger,
      'textarea-warning':warning,
      }]"
    class="vx-textarea vs-component vs-con-textarea">

    <label class="vs-input--label" v-if="label">
      {{ label }}
    </label>

    <textarea
      :value="value"
      v-bind="$attrs"
      class="vs-textarea"
      :style="style"
      v-on="listeners">
    </textarea>

    <div
      v-if="counter"
      class="count vs-textarea--count">
      {{ value ? value.length : 0 }} / {{ counter }}
    </div>


    <div
      v-if="success"
      key="success"
      class="con-text-validation vs-input--text-validation">
      <span class="span-text-validation span-text-validation-success vs-input--text-validation-span">
        {{
          successText
        }}
      </span>
    </div>
    <div
      v-else-if="danger"
      key="danger"
      class="con-text-validation span-text-validation-danger vs-input--text-validation-span">
      <span class="span-text-validation">
        {{
          dangerText
        }}
      </span>
    </div>
    <div
      v-else-if="warning"
      key="warning"
      class="con-text-validation span-text-validation-warning vs-input--text-validation-span">
      <span class="span-text-validation">
        {{
          warningText
        }}
      </span>
    </div>
    <div
      v-if="descriptionText"
      key="description"
      class="con-text-validation span-text-validation vs-input--text-validation-span">
      <span class="span-text-validation">
        {{
          descriptionText
        }}
      </span>
    </div>

  </div>
</template>

<script>

export default {
  name: "VxTextarea",
  inheritAttrs:false,
  props:{
    value:{},
    label:{
      default:null,
      type: String
    },
    color:{
      default:'primary',
      type:String
    },
    counter:{
      default: null,
      type: [Number, String]
    },
    counterDanger:{
      default: false,
      type: Boolean
    },
    height:{
      default:null,
      type: String
    },
    width:{
      default:null,
      type: String
    },
    success:{
      default:false,
      type:Boolean
    },
    danger:{
      default:false,
      type:Boolean
    },
    warning:{
      default:false,
      type:Boolean
    },
    successText:{
      default: null,
      type:String
    },
    dangerText:{
      default: null,
      type:String
    },
    warningText:{
      default: null,
      type:String
    },
    descriptionText:{
      default: null,
      type:String
    },
  },
  data:()=>({
    isFocus: false
  }),
  computed:{
    style() {
      let style = {}
      style.height = this.height
      style.width = this.width

      return style
    },
    listeners() {
      return {
        ...this.$listeners,
        input:(evt) => {
          this.$emit('input', evt.target.value)
        },
        focus:() => {
          this.focus()
        },
        blur:() => {
          this.blur()
        }
      }
    }
  },
  watch:{
    value() {
      if(this.value && this.value.length > this.counter) {
        this.$emit('update:counterDanger', true)
      } else {
        this.$emit('update:counterDanger', false)
      }
    }
  },
  methods:{
    focus() {
      this.isFocus = true
      this.$emit('focus')
    },
    blur() {
      this.isFocus = false
      this.$emit('blur')
    },
  }
}

</script>