<template>
  <div class="vx-col doc-upload mb-base" :class="colClass"  v-if="checkShowConditioned">
    <h4 v-if="title != ''" class="mb-4 text-center" v-html="title"></h4>
    <vx-card class="card-body-pt-small" :class="[{'not-upload': !checkUploadable}]">
      <div slot="no-body">
        <vx-upload
          v-if="checkUploadable"
          class="doc-vs-upload"
          text="Seleccione o arrastre un archivo"
          ref="vsupload"
          :automatic="automatic"
          :action="uploadAction"
          :alreadyLoaded="hasFile"
          :fileIsVerified="isVerified"
          :fileIsRejected="isRejected"
          :requiresValidation="docSets.verification_required"
          :loadedFileName="stateDefs.savedName"
          fileName="file"
          :data="formData"
          :acceptFileTypes="acceptList"
          :headers="headers"
          :disabled="isDisabled"
          @on-invalid-extension="onInvalidExtensionEvent"
          @change="onChangeEvent"
          @on-uploading="onUploadingEvent"
          @on-error="onErrorEvent"
          @on-set-file="onSetFile"
          @on-success="onSuccessEvent"
          @on-delete="onDeleteEvent" />
      </div>
      <div v-if="showAlerts" class="flex justify-between flex-wrap mb-4 small-flex">
        <vs-alert v-if="onError && !fileWasLoaded" color="danger" style="height: auto">{{ invalidExtension ? invalidExtensionError : genericError }} {{ apiErrors != '' ? apiErrors : '' }}</vs-alert>
        <vs-alert v-if="onError && fileWasLoaded" color="danger" style="height: auto">{{ invalidExtension ? invalidExtensionError : genericError }} {{ apiErrors != '' ? apiErrors : '' }} {{ remainMssg }}</vs-alert>
        <vs-alert v-if="onSuccess" color="success" style="height: auto">
          El archivo ha sido cargado correctamente{{(docSets.verification_required) ? " y será validado por el equipo de RedGirasol." : "."}}
        </vs-alert>
      </div>
      <h6 class="mb-2">{{ label }}</h6>
      <p class="text-grey small">{{ indicationLabel }}</p>

      <div v-if="showResults" class="flex justify-between status-doc mt-2">
        <span v-if="docSets.verification_required && !gotVerification" class="flex items-center">
          <feather-icon icon="ClockIcon" svgClasses="h-5 w-5 text-success stroke-current" />
          <span class="ml-2 small"><strong>Estado:</strong> Cargado, en espera de validación </span>
        </span>
        <span v-if="!docSets.verification_required && !gotVerification" class="flex items-center">
          <feather-icon icon="CheckIcon" svgClasses="h-5 w-5 text-success stroke-current" />
          <span class="ml-2 small"><strong>Estado:</strong> Cargado</span>
        </span>
        <span v-if="isVerified" class="flex items-center">
          <feather-icon icon="CheckCircleIcon" svgClasses="h-5 w-5 text-success stroke-current" />
          <span class="ml-2 small"><strong>Estado:</strong> Verificado <br> (el archivo ya no puede ser actualizado) </span>
        </span>
        <span v-if="isRejected" class="flex items-center">
          <feather-icon icon="AlertTriangleIcon" svgClasses="h-5 w-5 text-warning stroke-current" />
          <span class="ml-2 small"><strong>Estado:</strong> Rechazado <br>
            <strong>Motivo de rechazo:</strong> {{stateDefs.rejection_reason}} 
          </span>
        </span>
      </div>
      <div v-if="isUploading" class="flex justify-between status-doc mt-2">
        <span class="flex items-center">
          <feather-icon icon="LoaderIcon" svgClasses="h-5 w-5 text-warning stroke-current" />
          <span class="ml-2 small"><strong>Cargando...</strong></span>
        </span>
      </div>

      <vs-divider></vs-divider>

      <template v-if="!isDisabled && checkUploadable">
        <vs-button class="small p-2" type="border" color="primary" @click="openFilePrompt">{{ visibleStateLoaded ? "Actualizar " : "Seleccionar" }} documento</vs-button>
        <vs-divider></vs-divider>
      </template>

      <div v-if="canDownload" class="flex justify-between flex-wrap mb-0 small-flex">
        <vs-button class="mr small p-2" type="border" @click="onViewFileRequest" color="success">Ver</vs-button>
        <vs-button class="small p-2" type="border" color="success" @click="onDownloadFileRequest">Descargar</vs-button>
      </div>

      <div v-if="!visibleStateLoaded && !onError && checkUploadable && !preLoaded" class="flex justify-between flex-wrap mb-0 small-flex">
        <vs-alert color="dark" style="height:auto; min-height:28px"> Pendiente de cargar </vs-alert>
      </div>

      <div v-else-if="!checkUploadable" class="flex justify-between flex-wrap mb-0 small-flex">
        <vs-alert color="warning"> {{docAssoc.neg_mssg}} </vs-alert>
      </div>

      <template v-if="hasBackofficeAccess && canViewOperationActions">
        <vs-divider></vs-divider>
        <p class="text-grey small">Acciones para operaciones</p>
        <div class="flex justify-between flex-wrap mb-0 small-flex">
          <vs-button  class="small mt-3" type="border" color="dark" @click="e => { showInfoPrompt = true }">Info.</vs-button>
          <vs-button v-if="!gotVerification && docSets.verification_required" class="small mt-3" type="border" @click="verificationRequest" color="success">Verificar</vs-button>
          <vs-button v-if="!gotVerification && docSets.verification_required" class="small mt-3" type="border" color="danger" @click="rejectRequest">Rechazar</vs-button>
          <vs-button v-if="isVerified" class="small mt-3" type="border" color="danger" @click="revokeVerificationRequest">Revocar verificación</vs-button>
        </div>
      </template>

    </vx-card>

    <!-- Dialogo para rechazo -->
    <vs-popup
      v-if="hasBackofficeAccess"
      title="Rechazo de documento"
      :active.sync="showRejectPrompt">
      <div class="con-exemple-prompt">

        <p class="mb-5">Por favor defina la razón por la que se rechaza el documento: <strong>{{this.docName}}</strong></p>

        <vs-input
          class="w-full mb-4"
          label="Razón de rechazo"
          placeholder="Requerido"
          v-validate="'required'"
          data-vv-name="reject_reason"
          v-model.lazy="reject_reason" />

        <vs-button @click="executeRejection" :disabled="!this.reject_reason || this.reject_reason.length < 5" color="primary" class="mr-5 mt-2">Ejecutar rechazo</vs-button>
        <vs-button @click="closeRejectPrompt" type="flat" color="primary">Cancelar</vs-button>
      </div>
    </vs-popup>

    <!-- Dialogo para infor -->
    <vs-popup
      v-if="hasBackofficeAccess"
      title="Detalles del documento"
      :active.sync="showInfoPrompt">
      <div class="con-exemple-prompt">
        <h3 class="mb-3">{{docName}}</h3>
        <p class="mb-2">{{indicationLabel}}</p>
        <vs-divider></vs-divider>
        <p class="mb-5"><strong>Estado: </strong>{{ isVerified ? "Verificado" : (isRejected ? "Rechazado" : "En espera de verificación")}} </p>
        <p class="mb-5" v-if="isRejected"><strong>Razón de rechazo: </strong>{{ stateDefs.rejection_reason }} </p>
        <p class="mb-5"><strong>Última actualización: </strong>{{ stateDefs.updated_at }} </p>
        <p class="mb-5"><strong>ID usuario que realizó ajuste más reciente: </strong>{{ stateDefs.verified_by || "-" }} </p>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import VxUpload from '@upload/VxUpload'
const commonFileTypes = "pdf,jpg,jpeg,png";
const imagesFileTypes = "jpg,jpeg,png";
const unverifiedEnumValue = "sin verificar";
const verifiedEnumValue = "verificado";
const rejectedEnumValue = "rechazado";
const generalColClass = "xxl:w-1/4 xl:w-1/3 lg:w-1/2 md:w-1/2 sm:w-3/4 w-full";
const generalColLargeClass = "xxl:w-1/3 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-3/4 w-full";
const generalColExtraLargeClass = "xxl:w-1/2 xl:w-70% lg:w-3/4 md:w-5/6 sm:w-full w-full";

export default {
  name: "document-upload",
  props: {
    f: { type: Object, required: true },
    target_id: { type: [String,Number], default: null },
    doc: { type: Object, required: false },
    evaluator: { type: Function, required: false },
    // validationReceptor: { type: Function, required: false },
    disabled: { type: Boolean, default: false },
    multi_assoc_index: { type: [String, Number], default: null },
    loadedState: { type: Object, default: null },
    stickToLoadedState: { type: Boolean, default: false },
    inputClassLarge: {type: Boolean, default: false},
    inputClassExtraLarge: {type: Boolean, default: false},
    automatic: {type: Boolean, default: true},
    skipLoadedActions: {type: Boolean, default: false},
    initAsEmpty: {type: Boolean, default: false},
    from_project_id: {type: [String, Number], default: null},
    editableByBackoffice: {type: Boolean, default: false},
    isGeneralDoc: { type: Boolean, required: false, default: false },
    selfAccessToken: { type: String, default: null},
    title: { type: String, default: "" },
    isFromPublic: { type: Boolean, default: false },
  },
  components: {
    VxUpload
  },
  data(){
    return {
      onFileSelected: false,
      onError: false,
      onSuccess: false,
      hasFile: false,
      fileWasLoaded: false,
      visibleStateLoaded: false,
      invalidExtension: false,

      fieldSets: {
        doc_id: null,
        db_field: null,
        field_name: null,
        db_table: null,
        indication: null,
      },

      docSets: {
        is_required: null,
        verification_required: null,
        file_type: null,
        description: null,
        clasification: null,
        clasification_2: null,
        prefix: null,
      },

      hasAssoc: false,

      docAssoc: {
        table: null,
        assoc_key: null,
        condition: null,
        assoc_source: null,
        neg_mssg: null,
        multi_assoc: null,
      },

      stateDefs: {
        verified: null,
        db_id: null,
        savedName: null,
        rejected: null,
        rejection_reason: null,
        verified_by: null,
        updated_at: null,
      },

      showRejectPrompt: null,
      reject_reason: null,

      showInfoPrompt: null,
      isUploading: null,

      colClass: null,

      prepFileLoaded: false,
      customTargetId: null,
      empty: null,

      /* mensajes */
      genericError: "Ha ocurrido un error con la carga.",
      invalidExtensionError: "El tipo de archivo seleccionado no es válido, intente con otro archivo.",
      updateError: "Ha ocurrido un error al intentar actualizar",
      remainMssg: "El archivo cargado previamente seguirá activo.",
      apiErrors: ""
    }
  },
  computed:{
    currentAccessToken(){
      return this.selfAccessToken !== null ? this.selfAccessToken : this.AccessToken;
    },
    isDisabled(){
      return (this.stateDefs.verified || this.$props.disabled == true || !this.editableIfBackoffice) //&& this.docSets.block_when_verified);
    },
    isVerified(){
      return (this.stateDefs.verified);
    },
    isRejected(){
      return (this.stateDefs.rejected);
    },
    gotVerification(){
      return this.isVerified || this.isRejected;
    },
    preLoaded(){
      if(this.$props.automatic){
        return false;
      }
      else{
        return this.prepFileLoaded;
      }
    },
    canDownload(){
      return (this.visibleStateLoaded && !this.skipLoadedActions);
    },
    hasBackofficeAccess(){
      return this.$acl.check('isBackoffice');
    },
    canViewOperationActions(){
      return (this.fileWasLoaded);
    },
    showAlerts(){
      return (this.onSuccess || this.onError);
    },
    showResults(){
      return (this.isVerified || this.isRejected || this.visibleStateLoaded)  && !this.isUploading && !this.skipLoadedActions;
    },
    isUserDoc(){
      return this.docSets.clasification == 'user';
    },
    isProjectDoc(){
      return this.docSets.clasification == 'project';
    },
    acceptList(){
      return this.docSets.file_type;
    },
    label(){
      return this.docSets.name + (this.docSets.is_required ? " *" : "");
    },
    docName(){
      return this.docSets.name;
    },
    indicationLabel(){
      return this.fieldSets.indication || (this.docSets.is_required ? "Documento requerido" : "Documento opcional");
    },
    apiStorageBasePrefix(){
      return this.ApiDomain + "/storagev3/documents/";
    },
    uploadAction (){
      let url = this.apiStorageBasePrefix;
      if(this.fileWasLoaded){
        // actualizacion
        if(this.isGeneralDoc)
          return `${url}upload/${this.target_id}/update1/${this.stateDefs.db_id}`;
        if(this.isUserDoc)
          return `${url}upload/user/${this.target_id}/update/${this.stateDefs.db_id}`;
        if(this.isProjectDoc)
          return `${url}upload/project/${this.target_id}/update/${this.stateDefs.db_id}`;
      }
      else {
        if(this.isGeneralDoc)
          return `${url}upload/${this.target_id}`;
        if(this.isUserDoc)
          return `${url}upload/user/${this.target_id}`;
        if(this.isProjectDoc)
          return `${url}upload/project/${this.target_id}`;
      }
    },
    formData(){
      let obj = { file_type: this.docSets.prefix };
      if(this.assocData != null){
        obj.assocData = this.assocData;
      }
      if(this.multi_assoc_index != null){
        obj.assocIndex = this.multi_assoc_index;
      }

      if(this.isRejected && this.$props.from_project_id != null){
        obj.from_project_id = this.$props.from_project_id;
      }

      if(this.isGeneralDoc && this.hasAssoc) {
        obj.assocTable = this.docAssoc.table;
        obj.assocKey = this.docAssoc.assoc_key;
      }

      if(this.f.section_id != 42){
        if(!this.isFromPublic) {
          this.injectAccountMetadataToFormData(obj);
        }
      }
      return obj;
    },
    headers(){
      return {
        "Access-Control-Allow-Origin": process.env.VUE_APP_API_DOMAIN,
        "Access-Control-Allow-Credentials": process.env.VUE_APP_CORS_ALLOW_CREDENTIALS,
        "X-Requested-With": "XMLHttpRequest",
        "Authorization": "Bearer " + this.currentAccessToken,
      };
    },
    checkShowConditioned(){
      return !this.accessOnlyByBackoffice && (!this.$props.f.show_conditioned ||
        (this.$props.f.show_conditioned && this.$props.f.condition != null && this.evaluator != null && this.evaluator(this.$props.f.condition) == true));
    },
    accessOnlyByBackoffice(){
      if(this.$props.f.bo_private == 1){
        return !this.hasBackofficeAccess;
      }
      return false;
    },
    editableIfBackoffice(){
      if(this.hasBackofficeAccess){
        return this.$props.f.bo_private == 1 || this.$props.editableByBackoffice == true;
      }
      return true;
    },
    checkUploadable(){
      if(this.hasAssoc){
        return (this.evaluator != null && this.evaluator(this.docAssoc.condition) == true);
      }
      else {
        return true;
      }
    },
    assocData(){
      if(this.hasAssoc && this.evaluator != null){
        return this.evaluator(this.docAssoc.assoc_source);
      }
      else {
        return null;
      }
    }
  },
  async beforeMount(){
    if(this.$props.initAsEmpty){
      this.empty = true;
    }

    if(this.$props.f){
      this.fieldSets.doc_id = this.$props.f.doc_id;
      this.fieldSets.db_field = this.$props.f.db_field;
      this.fieldSets.field_name = this.$props.f.field_name;
      this.fieldSets.db_table = this.$props.f.db_table;
      this.fieldSets.indication = this.$props.f.indication;

      if(this.$props.f.div_class != null){
        this.colClass = this.$props.f.div_class;
      }
      else {
        if(this.$props.inputClassExtraLarge){
          this.colClass = generalColExtraLargeClass;
        }
        else if(this.$props.inputClassLarge){
          this.colClass = generalColLargeClass;
        }
        else {
          this.colClass = generalColClass;
        }
      }

      if(this.$props.f.extras){
        let extras = JSON.parse(this.$props.f.extras);
        if(extras.doc_assoc){
          this.hasAssoc = true;
          this.docAssoc.table = extras.doc_assoc.table;
          this.docAssoc.assoc_key = extras.doc_assoc.assoc_key;
          this.docAssoc.condition = extras.doc_assoc.condition;
          this.docAssoc.assoc_source = extras.doc_assoc.assoc_source;
          this.docAssoc.assoc_relation = extras.doc_assoc.assoc_relation;
          this.docAssoc.neg_mssg = extras.doc_assoc.neg_mssg;
          this.docAssoc.multi_assoc = extras.doc_assoc.multi_assoc || false;
        }
      }
    }

    if(this.$props.doc){
      this.docSets.is_required = this.$props.doc.is_required == 1;
      this.docSets.verification_required = this.$props.doc.verification_requiered == 1;
      this.docSets.description = this.$props.doc.description;
      this.docSets.clasification = this.$props.doc.clasification;
      this.docSets.clasification_2 = this.$props.doc.clasification_2;
      this.docSets.prefix = this.$props.doc.prefix;
      this.docSets.name = this.$props.doc.name;
      //this.docSets.block_when_verified = this.$props.doc.block_when_verified == 1;
      let ft = JSON.parse(this.$props.doc.file_type);
      this.docSets.file_type = ft;

      // obtener estatus del documento para el target correspondiente
      let file = null;
      if(this.loadedState != null){
        file = this.loadedState;
      }
      else {
        if(this.stickToLoadedState){
          return;
        }

        let files = await this.getDocumentFiles();
        let ll = 0;

        if(this.hasAssoc && this.multi_assoc_index != null){
          ll = this.multi_assoc_index;
        }

        if(files && files.length > ll){
          file = files[ll];
        }
      }

      if(file){
        this.hasFile = true;
        this.fileWasLoaded = true;
        this.stateDefs.verified = (file.is_verified == verifiedEnumValue);
        this.stateDefs.rejected = (file.is_verified == rejectedEnumValue);
        this.stateDefs.db_id = file.id;
        this.stateDefs.savedName = file.storage_file;
        this.stateDefs.rejection_reason = file.reject_reason;
        this.stateDefs.verified_by = file.verified_by;
        this.stateDefs.updated_at = file.updated_at;
      }

      this.visibleStateLoaded = this.fileWasLoaded;

      if(this.initAsEmpty){
        this.$refs.vsupload.activateUpdateMode();
        this.visibleStateLoaded = false;
        this.stateDefs.verified = false;
        this.stateDefs.rejected = false;
      }
    }
  },
  methods: {
    async saveFile (){
      this.$refs.vsupload.uploadCurrentFile();
    },
    async saveFileWithTarget(target){
      let action = this.customUploadAction(target);
      this.$refs.vsupload.uploadCurrentFileFromParent(action);
    },
    async getDocumentFiles(){
      if(!this.target_id){
        return [];
      }

      let url = this.apiStorageBasePrefix;
      let getUrl = null;

      if(this.hasAssoc && this.docAssoc.assoc_source){
        // agregar información para asociacion
        getUrl = `${url}files/${this.fieldSets.doc_id}/getFilesByAssociated`;
        let params = `?assoc_table=${this.docAssoc.table}&assoc_key=${this.docAssoc.assoc_key}&assoc_id=${this.assocData}`;
        if(this.docAssoc.assoc_relation != null){
          params += `&assoc_relation=${this.docAssoc.assoc_relation}`;
        }
        getUrl += params;
      }
      else {
        if(this.isGeneralDoc)
          getUrl = `${url}files/${this.fieldSets.doc_id}/${this.target_id}/${this.docAssoc.table}/${this.docAssoc.assoc_key}`;
        else if(this.isUserDoc)
          getUrl = `${url}files/${this.fieldSets.doc_id}/user/${this.target_id}`;
        else if(this.isProjectDoc)
          getUrl = `${url}files/${this.fieldSets.doc_id}/project/${this.target_id}`;

        if(!getUrl){ return [] }
      }

      try {
        let res = await this.publicApiGet(getUrl);
        return res.data;
      }
      catch (error) {
        return [];
      }
    },
    openFilePrompt(){
      this.$refs.vsupload.openFilePrompt();
    },
    onUploadingEvent(){
      this.isUploading = true;
    },
    onSuccessEvent(res){
      let data = JSON.parse(res);
      this.resetFlags();
      this.apiErrors =  "",
      this.onSuccess = true;
      this.fileWasLoaded = true;
      this.visibleStateLoaded = true;
      this.empty = false;

      this.stateDefs.verified = (data.is_verified == verifiedEnumValue);
      this.stateDefs.rejected = (data.is_verified == rejectedEnumValue);
      this.stateDefs.db_id = data.id;
      this.stateDefs.updated_at = data.updated_at;

      this.$emit("on-success", data);
    },
    onErrorEvent(e){
      this.apiErrors = "";
      if(e.currentTarget.status != 200) {
        const error = JSON.parse(e.currentTarget.response)
        const file = JSON.parse(error.error);
        file.file.forEach(element => {
          this.apiErrors += element + " "
        });
      }
      this.resetFlags();
      this.onError = true;
      this.$emit("on-error");
    },
    onInvalidExtensionEvent(){
      this.resetFlags();
      this.onError = true;
      this.invalidExtension = true;
    },
    onChangeEvent(){
      this.resetFlags();
    },
    onDeleteEvent(){
      this.resetFlags();
    },
    onSetFile(){
      // llamado cuando el archivo es cargado en el componente (no es el envío)
      this.prepFileLoaded = true;
    },
    async onViewFileRequest(){
      let url;
      if(this.isGeneralDoc)
        url = this.apiStorageBasePrefix + `view_document_file/${this.stateDefs.db_id}/${this.target_id}?access_token=${this.currentAccessToken}`;
      else if(this.isUserDoc)
        url = this.apiStorageBasePrefix + `view_document_file/${this.stateDefs.db_id}/user/${this.target_id}?access_token=${this.currentAccessToken}`;
      else if(this.isProjectDoc)
        url = this.apiStorageBasePrefix + `view_document_file/${this.stateDefs.db_id}/project/${this.target_id}?access_token=${this.currentAccessToken}`;

      try {
        //window.open(url, "_blank");
        window.open(url,url,'addressbar=0,directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=0,resizable=0,height=700,width=800');
      }
      catch (error) {
      }
    },
    onDownloadFileRequest(){
      let url;
      if(this.isGeneralDoc)
        url = this.apiStorageBasePrefix + `download_document_file/${this.stateDefs.db_id}/${this.target_id}?access_token=${this.currentAccessToken}`;
      else if(this.isUserDoc)
        url = this.apiStorageBasePrefix + `download_document_file/${this.stateDefs.db_id}/user/${this.target_id}?access_token=${this.currentAccessToken}`;
      else if(this.isProjectDoc)
        url = this.apiStorageBasePrefix + `download_document_file/${this.stateDefs.db_id}/project/${this.target_id}?access_token=${this.currentAccessToken}`;

      try {
        window.open(url, "_blank");
      }
      catch (error) {
      }
    },
    verificationRequest(){
      if(this.hasBackofficeAccess){
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: '¿Desea verificar el documento?',
          text: `Confirme que desea marcar al documento "${this.docName}" como Verificado.`,
          acceptText: "Confirmar",
          cancelText: 'Cancelar',
          accept: this.executeVerification
        });
      }
    },
    async executeVerification(){
      if(this.hasBackofficeAccess){
        this.showLoading(true);
        try {
          let res = await this.publicApiPost(this.apiStorageBasePrefix + `verify`, {id: this.stateDefs.db_id, from_project_id: this.$props.from_project_id} );
          if(res.data){
            this.stateDefs.verified = (res.data.is_verified == verifiedEnumValue);
            this.stateDefs.verified_by = res.data.verified_by;
            this.stateDefs.updated_at = res.data.updated_at;
            /*if(this.validationReceptor){
              this.validationReceptor(this.fieldSets.doc_id);
            }*/
          }
          this.$emit("on-verified", res.data);
          this.onSuccess = false;
          this.hasFile = true;
          this.showProcessSuccessNotif("Operación exitosa", "Documento verificado correctamente");
        }
        catch (error) {
          this.warn(error.response);
          let mssg = "";
          if(error.response.status == 403){
            mssg = "No cuenta con los permisos necesarios para realizar la verificación.";
          }
          else {
            mssg = error.response.data;
          }
          this.showProceessErrorNotif("Error en verificación de documento", mssg);
        }
        this.showLoading(false);
      }
    },
    rejectRequest(){
      if(this.hasBackofficeAccess){
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: '¿Desea rechazar el documento?',
          text: `Confirme que desea marcar al documento "${this.fieldSets.field_name}" como Rechazado.`,
          acceptText: "Confirmar",
          cancelText: 'Cancelar',
          accept: () => { this.rejection_reason = null; this.showRejectPrompt = true; }
        });
      }
    },
    async executeRejection(){
      if(this.hasBackofficeAccess){
        this.showLoading(true);
        try {
          let res = await this.publicApiPost(this.apiStorageBasePrefix + `reject`, {id: this.stateDefs.db_id, reject_reason: this.reject_reason,
            from_project_id: this.$props.from_project_id} );
          if(res.data){
            this.stateDefs.verified = (res.data.is_verified == verifiedEnumValue);
            this.stateDefs.rejected = (res.data.is_verified == rejectedEnumValue);
            this.stateDefs.rejection_reason = res.data.reject_reason;
            this.stateDefs.verified_by = res.data.verified_by;
            this.stateDefs.updated_at = res.data.updated_at;
          }
          this.closeRejectPrompt();
          this.$emit("on-rejected", res.data);
          this.onSuccess = false;
          this.showProcessSuccessNotif("Operación exitosa", "Documento rechazado correctamente");
        }
        catch (error) {
          this.warn(error.response);
          let mssg = "";
          if(error.response.status == 403){
            mssg = "No cuenta con los permisos necesarios para realizar el rechazo.";
          }
          else {
            mssg = error.response.data;
          }
          this.showProceessErrorNotif("Error en rechazo de documento", mssg);
        }
        this.showLoading(false);
      }
    },
    revokeVerificationRequest(){
      if(this.hasBackofficeAccess){
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: '¿Desea revocar la verificación del documento?',
          text: `Confirme que desea revocar el estado de "Verificado" al documento "${this.docName}". El estado volverá a "En espera de validación".`,
          acceptText: "Confirmar",
          cancelText: 'Cancelar',
          accept: this.executeRevokeVerification
        });
      }
    },
    async executeRevokeVerification(){
      if(this.hasBackofficeAccess){
        this.showLoading(true);
        try {
          let res = await this.publicApiPost(this.apiStorageBasePrefix + `unverify`, {id: this.stateDefs.db_id, from_project_id: this.$props.from_project_id} );
          if(res.data){
            this.stateDefs.verified = (res.data.is_verified == verifiedEnumValue);
            this.stateDefs.rejected = (res.data.is_verified == rejectedEnumValue);
            this.stateDefs.verified_by = res.data.verified_by;
            this.stateDefs.updated_at = res.data.updated_at;
          }
          this.$emit("on-revoked-verification", res.data);
          this.onSuccess = false;
          this.showProcessSuccessNotif("Operación exitosa", "Estado del documento actualizado correctamente");
        }
        catch (error) {
          this.warn(error.response);
          let mssg = "";
          if(error.response.status == 403){
            mssg = "No cuenta con los permisos necesarios para realizar la operación.";
          }
          else {
            mssg = error.response.data;
          }
          this.showProceessErrorNotif("Error en actualización de documento", mssg);
        }
        this.showLoading(false);
      }
    },
    checkForNewFileLoaded(){
      return this.$refs.vsupload.hasValidFileLoaded();
    },
    checkForNewFileSaved(){
      return this.fileWasLoaded && !this.empty;
    },
    getDocSet(){
      return this.docSets;
    },
    customUploadAction (target){
      let url = this.apiStorageBasePrefix;
      if(this.fileWasLoaded){
        // actualizacion
        return this.isUserDoc ?
          `${url}upload/user/${target}/update/${this.stateDefs.db_id}`
          : (this.isProjectDoc ? `${url}upload/project/${target}/update/${this.stateDefs.db_id}` : null);
      }
      else {
        return this.isUserDoc ? `${url}upload/user/${target}` : (this.isProjectDoc ? `${url}upload/project/${target}` : null);
      }
    },
    resetFlags(){
      this.onSuccess = false;
      this.onError = false;
      this.hasFile = false;
      this.invalidExtension = false;
      this.isUploading = false;
      this.prepFileLoaded = false;
    },
    closeRejectPrompt(){
      this.showRejectPrompt = false;
      this.rejection_reason = null;
    },
    showProceessErrorNotif(title, text){
      this.$vs.notify({
        title: title,
        text: text,
        color:'danger',
        iconPack: 'feather',
        position: 'top-center',
        fixed: true,
        icon:'icon-x-circle'
      });
    },
    showProcessSuccessNotif(title, text,){
      this.$vs.notify({
        title: title,
        text: text,
        color:'success',
        iconPack: 'feather',
        position: 'top-center',
        time: '8000',
        icon:'icon-check'
      });
    }
  }
}
</script>
