<template>
  <div class="con-upload vx-upload">
    <view-upload v-if="viewActive" :src="viewSrc" />

    <div class="con-img-upload" v-if="showInput">
      <div
        v-for="(img,index) in getFilesFilter"
        :class="{
          'fileError':img.error,
          'removeItem':itemRemove.includes(index)
        }"
        :key="index"
        class="img-upload"
      >
        <button class="btn-x-file" type="button" @click="removeFile(index)">
          <i translate="no" class="material-icons notranslate">clear</i>
        </button>

        <button
          class="btn-upload-file on-progress ready-progress"
          :class="{'loading-progress': (!img.success && ! img.error) }"
          style="height: 100%">
          <i translate="no" class="material-icons notranslate"> 
            {{ img.success ? completedIcon : (img.error ? 'cloud_off' : 'cloud_upload') }}
          </i>
        </button>

        <img
          v-if="img.src"
          :style="{
            maxWidth:img.orientation == 'h'?'100%':'none',
            maxHeight:img.orientation == 'w'?'100%':'none'
          }"
          :key="index"
          :src="img.src"
          @touchend="viewImage(img.src,$event)"
          @click="viewImage(img.src,$event)"
          alt="img"
        />
        <h4 v-if="!img.src" class="text-archive">
          <i translate="no" class="material-icons notranslate">description</i>
        </h4>
      </div>

      <div
        :class="{
          'on-progress-all-upload':percent != 0,
          'is-ready-all-upload':percent >= 100,
          'disabled-upload':$attrs.hasOwnProperty('disabled') || limit?(srcs.length - itemRemove.length) >= Number(limit):false
        }"
        class="con-input-upload"
      >
        <input
          ref="fileInput"
          v-bind="$attrs"
          :disabled="disabled"
          :accept="acceptExtensions"
          type="file"
          @change="getFiles"
        />
        <span class="text-input" v-if="!disabled">{{ textHint }}</span>
        <span class="text-input" v-if="disabled">La carga está deshabilitada</span>
        <span class="text-input small" v-if="!acceptAll && !disabled">( {{ acceptHint }} )</span>

      </div>
    </div>

    <div class="con-img-upload" v-if="!showInput">
      <div class="img-upload">
         <button v-if="!fileIsVerified && !disabled" class="btn-x-file" type="button" @click="activateUpdateMode()">
          <vx-tooltip
          title="Cargar otro archivo"
          color="primary"
          position="left"
          text="De click en el icono para poder cargar un nuevo documento">
          <i translate="no" class="material-icons notranslate">clear</i>
        </vx-tooltip>
        </button>
      
        <button
          class="btn-upload-file on-progress ready-progress"
          :class="{'rejection-progress': fileIsRejected }"
          style="height: 100%">          
        </button>
        <h4 class="text-archive">
          <i translate="no" class="material-icons notranslate">{{loadedFileIcon}}</i>          
        </h4>
      </div>
    </div>    
  </div>
</template>
<script>
import viewUpload from "./VxViewUpload";
const REGEX = /(?:\.([^.]+))?$/;
const imageExtensions = ['png', 'jpg', 'jpeg', 'bmp'];

var lastTap = 0;
export default {
  name: "vx-upload",
  components: {
    viewUpload
  },
  inheritAttrs: false,
  props: {
    fileName: {
      default: null,
      type: String
    },
    text: {
      default: "Upload File",
      type: String
    },
    textMax: {
      default: "Maximum of files reached",
      type: String
    },
    limit: {
      default: 1,
      type: [Number, String]
    },
    action: {
      default: null,
      type: String
    },
    headers: {
      default: null,
      type: Object
    },
    data: {
      default: null,
      type: Object
    },
    automatic: {
      default: true,
      type: Boolean
    },
    showUploadButton: {
      default: true,
      type: Boolean
    },
    singleUpload: {
      default: false,
      type: Boolean
    },
    acceptFileTypes: {
      default: "all",
      type: [Array, String]
    },
    disabled: {
      default: false,
      type: Boolean
    },
    alreadyLoaded: {
      default: false,
      type: Boolean
    },
    loadedFileType: {
      default: '',
      type: String
    },
    fileIsVerified: {
      default: false,
      type: Boolean
    },
    fileIsRejected: {
      default: false,
      type: Boolean
    },
    requiresValidation: {
      default: false,
      type: Boolean
    },
    loadedFileName: {
      default: '',
      type: String
    }
  },
  data: () => ({
    inputValue: null,
    type: null,
    srcs: [],
    filesx: [],
    itemRemove: [],
    percent: 0,
    viewActive: false,
    viewSrc: null,
    acceptAll: false,
    acceptList: [],
    acceptHint: null,
    acceptExtensions: [],
    onInvalidExtension: false,
    onUpdateMode: false,
  }),
  beforeMount(){
    if(Array.isArray(this.$props.acceptFileTypes)){
      this.acceptList = this.$props.acceptFileTypes;
      this.acceptAll = false;
      
      let c = "";
      let h = "";
      this.acceptList.forEach(f => {
        if(c != ""){
          c += ", ";
          h += ", ";
        }
        c += "." + f;
        h += f; //.replace(".", "");
      });

      this.acceptHint = h;
      this.acceptExtensions = c;
    }
    else {
      let px = this.$props.acceptFileTypes;
      this.acceptHint = px;
      this.acceptExtensions = px;

      if(px == null || px == "all"){
        this.acceptAll = true;
      }
      else {
        this.acceptList = px.replace(/\s/g, "").split(',');
      }
    }
  },
  computed: {
    showInput(){
      if(this.alreadyLoaded){
        if(this.fileIsVerified){
          return false
        }
        else{
          return this.onUpdateMode;
        }
      }     
      return true;
    },
    completedIcon(){
      //return this.requiresValidation ? "watch_later" : "cloud_done";
      return "cloud_done";
    },
    loadedFileIcon(){
      let ext = REGEX.exec(this.loadedFileName)[1];
       if (imageExtensions.includes(ext)){
         return "panorama";
       }
       else {
         return "description"
       }
    },
    getFilesFilter() {
      let files = this.srcs.filter(item => {
        return !item.remove;
      });
      return files;
    },
    getCurrentLoaded() {
      let files = this.srcs.filter(item => {
        return !item.discarded && !item.remove;
      });
      return files;
    },
    getLoadedCount(){
      return this.getCurrentLoaded.length;
    },
    postFiles() {
      let postFiles = Array.prototype.slice.call(this.filesx);
      postFiles = postFiles.filter(item => {
        return !item.hasOwnProperty("remove");
      });
      return postFiles.length;
    },
    textHint(){
      return this.onInvalidExtension ? "Archivo con extensión no válida, limitada a" : this.$props.text;
    }
  },
  watch: {
    percent() {
      if (this.percent >= 100) {
        this.srcs.forEach(file => {
          file.percent = 100;
        });
        setTimeout(() => {
          this.percent = 0;
        }, 1000);
      }
    },
    getLoadedCount(newVal, oldVal){
      if(oldVal == 0 && newVal == 1 && this.automatic){
        this.uploadCurrentFile();
      }
    }
    
  },
  methods: {
    viewImage(src, evt) {
      var timeout;
      var eventx =
        "ontouchstart" in window ||
        (window.DocumentTouch && document instanceof window.DocumentTouch)
          ? "touchstart"
          : "click";
      if (eventx == "click") {
        this.viewActive = true;
        this.viewSrc = src;
      } else {
        if (evt.type == "touchend") {
          var currentTime = new Date().getTime();
          var tapLength = currentTime - lastTap;
          clearTimeout(timeout);
          if (tapLength < 500 && tapLength > 0) {
            this.viewActive = true;
            this.viewSrc = src;
            event.preventDefault();
          }
          lastTap = currentTime;
        }
      }
    },
    async openFilePrompt(){
      if(this.showInput){
        if(this.getCurrentLoaded.length > 0){
          this.removeFile(this.getFilesFilter.length -1);
        }
      }
      else {
        this.activateUpdateMode();
      }
      
      await this.$nextTick();
      // Add the component back in
      const input = this.$refs.fileInput;
      input.click();
    },
    activateUpdateMode(){
      this.onUpdateMode = true;
    },
    removeFile(index) {
      this.onInvalidExtension = false;
      this.itemRemove.push(index);
      this.srcs[index].discarded = true;
      this.$emit("on-delete", this.filesx[index]);
      setTimeout(() => {
        this.filesx[index].remove = true;
      }, 301);
    },
    getFiles(e) {      
      this.$emit("update:vsFile", e.target.value);
      this.onInvalidExtension = false;

      let _this = this;
      function uploadImage(e) {
        let orientation = "h";
        var image = new Image();
        image.src = e.target.result;
        image.onload = function() {
          if (this.width > this.height) {
            orientation = "w";
          }
          switchImage(this, orientation);
        };
      }
      function switchImage(image, orientation) {
        _this.srcs.push({
          src: image.src,
          orientation: orientation,
          type: _this.typex,
          percent: null,
          error: false,
          success: false,
          remove: null,
          discarded: null,
        });
      }
      
      var files = e.target.files;
      let count = this.srcs.length - this.itemRemove.length;
      let _index = 0;
      for (const file in files) {
        if (files.hasOwnProperty(file)) {
          if (this.limit) {
            count++;
            if (count > Number(this.limit)) {
              break;
            }
          }

          // validar extension
          const filex = files[file];
          let res = this.validateFileExtension(filex.name);
          if(!res){
            this.$emit("on-invalid-extension", e.target.value, this.filesx);
            this.onInvalidExtension = true;
            continue;
          }

          var reader = new FileReader();
          if (/image.*/.test(filex.type)) {
            this.typex = "image";
            this.filesx.push(filex);
            reader.onload = uploadImage;
            reader.readAsDataURL(filex);
          } else if (/video.*/.test(filex.type)) {
            this.typex = "video";
            this.filesx.push(filex);
            _this.srcs.push({
              src: null,
              name: filex.name,
              type: "video",
              percent: null,
              error: false,
              success: false,
              remove: null,
              discarded: null
            });
          } else {
            this.filesx.push(filex);
            _this.srcs.push({
              src: null,
              name: filex.name,
              percent: null,
              error: false,
              success: false,
              remove: null,
              discarded: null
            });
          }
          _index += 1;
          this.$emit("change", e.target.value, this.filesx);
          this.$emit("on-set-file", e.target.value, this.filesx);
        }
      }
      
      const input = this.$refs.fileInput;
      input.type = "text";
      input.type = "file";
    },
    upload(index, action) {
      const formData = new FormData();
      let postFiles = Array.prototype.slice.call(this.filesx);
      if (typeof index == "number") {
        postFiles = [postFiles[index]];
      } else if (index == "all") {
        postFiles = postFiles.filter(item => {
          return !item.hasOwnProperty("remove");
        });
      }

      const data = this.data || {};
      for (var key in data) {
        formData.append(key, data[key]);
      }

      if (this.singleUpload) {
        postFiles.forEach(filex => {
          const formData = new FormData();
          for (var key in data) {
            formData.append(key, data[key]);
          }
          formData.append(this.fileName, filex, filex.name);

          this.uploadx(index, formData, action);
        });
      } else {
        postFiles.forEach(filex => {
          formData.append(this.fileName, filex, filex.name);
        });
        this.uploadx(index, formData, action);
      }
    },
    uploadx(index, formData, action) {
      let self = this;
      const xhr = new XMLHttpRequest();
      self.$emit("on-uploading");
      xhr.onerror = function error(e) {
        self.$emit("on-error", e);
        if (typeof index == "number") {
          self.srcs[index].error = true;
        }
      };

      xhr.onload = function onload(e) {
        if (xhr.status < 200 || xhr.status >= 300) {
          self.$emit("on-error", e);
          if (typeof index == "number") {
            self.srcs[index].error = true;
          }
        } else {
          self.srcs[index].success = true;
          self.$emit("on-success", xhr.response);
        }
      };

      if (xhr.upload) {
        xhr.upload.onprogress = function progress(e) {
          if (e.total > 0) {
            let percent = (e.loaded / e.total) * 100;
            if (typeof index == "number") {
              self.srcs[index].percent = Math.trunc(percent);
            } else {
              self.percent = Math.trunc(percent);
            }
          }
        };
      }

      // xhr.withCredentials = false;
      xhr.withCredentials = process.env.VUE_APP_CORS_ALLOW_CREDENTIALS;
      // console.log(action);
      xhr.open("POST", action, true);

      const headers = this.headers || {};
      // console.log(headers);

      for (let head in headers) {
        if (headers.hasOwnProperty(head) && headers[head] !== null) {
          xhr.setRequestHeader(head, headers[head]);
        }
      }

      xhr.send(formData);
    },

    /* VALIDAR EXTENSION DEL ARCHIVO */
    validateFileExtension(filename){
      let ext = REGEX.exec(filename)[1];
      let dext = ext.toLowerCase();
      if(this.acceptAll){
        return true;
      }
      else{
        return this.acceptList.includes(dext);
      }
    },

    uploadCurrentFile() {
      if(this.getLoadedCount < 1 ){
        return;
      }

      let current = null;
      this.getFilesFilter.forEach(function(el, index) {
        if(!el.discarded){
          current = index;
        }
      });

      this.upload(current, this.$props.action);
    },

    /* OPCION DE SER INVOCADO DESDE EL PADRE */
    uploadCurrentFileFromParent(action) {
      if(this.getLoadedCount < 1 ){
        return;
      }

      let current = null;
      this.getFilesFilter.forEach(function(el, index) {
        if(!el.discarded){
          current = index;
        }
      });

      this.upload(current, action);
    },

    /* verificacion desde el padre  */
    hasValidFileLoaded(){
      return this.getLoadedCount > 0;
    }
  }
};
</script>