<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card :class="currentBg">
        <h1 class="rg-big-title extrabold">
            <slot name="text"></slot>
        </h1>
      </vx-card>
    </div>
  </div>
</template>
<script>
export default {
  name: 'big-title-color',
  props: {
    variantClass: {
      type: String,
      required: true,
      default: 'primary',
      validator: function (value) {
        // The value must match one of these strings
        return ['primary', 'dark', 'clear'].indexOf(value) !== -1
      }
    }
  },
  data(){
    return {
      currentBg: null,
    }
  },
  beforeMount() {
    this.autoSet();
  },
  methods: {
    autoSet(){
      switch (this.variantClass){
        case 'primary':
          this.currentBg = "big-title-card-bg";
          break;
        case 'dark':
          this.currentBg = "big-title-card";
          break;
        case 'clear':
          this.currentBg = "big-title-card-w";
          break;
      }
    }
  }
}
</script>