<!-- =========================================================================================
    File Name: RgGeolocator.vue
    Description: component to get the geolocation from the browser
    Implementation: 
      1.- Set component where required
          <rg-geolocator ref="rgGeolocator" />
      2.- Call method to ask for geolocation
          this.$refs.rgGeolocator.askForGeolocation();
      3.- Access to geolocation (if exists)
          this.CurrentGeolocationData
========================================================================================== -->
<template>
  <div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name:'rg-geolocator',
  beforeDestroy() {
    this.setCurrentGeolocationData(null);
  },
  data() {
    return {
      currentLocation: null,
      locationOptions: {
        enableHighAccuracy: true,
        timeout: Infinity,
        maximumAge: 0
      }
    };
  },
  methods: {
    ...mapMutations('auth', ['setCurrentGeolocationData']),
    async askForGeolocation(){
      if('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(this.onSuccessLocation, this.onErrorLocation, this.locationOptions);
      }
    },
    async onSuccessLocation(pos){
      this.currentLocation = "Lat: "+pos.coords.latitude+", Long "+pos.coords.longitude;
      this.setCurrentGeolocationData(this.currentLocation);
    },
    onErrorLocation(err){
      // console.log(err);
    },
  }
};
</script>