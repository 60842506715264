<template functional>
  <span>
    <div
      class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
      style="height: 20px;"
    >
      <span class="span-text-validation">{{props.error}}</span>
    </div>
  </span>
</template>

<script>
export default{
  name: 'v-error',
}
</script>
