<template>
  <img :src="url()" :alt="alt" >
</template>

<script>
export default {
  name: "image-from-source",  
  props: ['path', 'alt'],
  methods: {
    url() {
       return this.$data.StorageURL + this.path
    }
  }
};
</script>