/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


const mutations = {


  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE (state, value) {
    state.isVerticalNavMenuActive = value
  },
  TOGGLE_REDUCE_BUTTON (state, val) {
    state.reduceButton = val
  },
  UPDATE_MAIN_LAYOUT_TYPE (state, val) {
    state.mainLayoutType = val
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN (state, val) {
    state.verticalNavMenuItemsMin = val
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH (state, width) {
    state.verticalNavMenuWidth = width
  },
  TOGGLE_IS_CALENDAR_POPUP_ACTIVE (state, value) {
    state.isCalendarPopupActive = value
  },
  TOGGLE_IS_PAYMENTS_POPUP_ACTIVE (state, value) {
    state.isPaymentsPopupActive = value
  },
  TOGGLE_IS_PROJECT_PAYMENTS_POPUP_ACTIVE (state, value) {
    state.isProjectPaymentsPopupActive = value
  },
  TOGGLE_IS_FEEDBACK_POPUP_ACTIVE (state, value) {
    state.isFeedbackPopupActive = value
  },
  TOGGLE_IS_GENERAL_LOG_POPUP_ACTIVE (state, value) {
    state.isGeneralLogPopupActive = value
  },
  TOGGLE_IS_MATI_POPUP_ACTIVE (state, value) {
    state.isMatiPopupActive = value
  },
  SET_USER_ID_TO_ACTIVATE (state, value) {
    state.userIdToActivate = value
  },
  TOGGLE_IS_DETAILED_SIMULATION_POPUP_ACTIVE (state, value) {
    state.isDSActive = value;
  },
  TOGGLE_IS_PROJECT_DETAIL_POPUP_ACTIVE (state, value) {
    state.isProjectDetailPopupActive = value
  },
  TOGGLE_IS_NEW_CARD_POPUP_ACTIVE (state, value) {
    state.isNewCardPopupActive = value
  },
  TOGGLE_IS_CARD_CVV_POPUP_ACTIVE (state, value) {
    state.isCardCvvPopupActive = value
  },
  TOGGLE_IS_REFUND_POPUP_ACTIVE (state, value) {
    state.isRefundCardPopupActive = value
  },
  TOGGLE_IS_SIMULATION_POPUP_ACTIVE (state, value) {
    state.isSimulationPopupActive = value
  },
  TOGGLE_IS_INVESTMENT_POPUP_ACTIVE (state, value) {
    state.isInvestmentPopupActive = value
  },
  TOGGLE_IS_2FA_POPUP_ACTIVE (state, value) {
    state.is2faPopupActive = value
  },
  TOGGLE_IS_BENEFICIARY_POPUP_ACTIVE (state, value) {
    state.isBeneficiaryPopupActive = value
  },
  TOGGLE_IS_BENEFICIARY_DETAIL_POPUP_ACTIVE (state, value) {
    state.isBeneficiaryDetailPopupActive = value
  },
  TOGGLE_IS_BANK_ACCOUNTS_POPUP_ACTIVE (state, value) {
    state.isBankAccountsPopupActive = value
  },
  TOGGLE_IS_INCOME_SOURCE_PROOF_POPUP_ACTIVE (state, value) {
    state.isIncomeSourceProofPopupActive = value
  },
  SET_MATI_PROCESS_IS_RUNNING (state, value) {
    state.runningMatiProcess = value
  },
  SET_MATI_PROCESS_IS_FINISHED (state, value) {
    state.finishedMatiProcess = value
  },
  SET_MIFIEL_CM_SUPPLIER_PROCESS_IS_FINISHED (state, value) {
    state.finishedMiFielSupplierCMProcess = value
  },
  TOGGLE_IS_GENERAL_NOTICE_POPUP_ACTIVE (state, value) {
    state.isGeneralNoticePopupActive = value
  },
  TOGGLE_IS_SWITCH_ACCOUNT_POPUP_ACTIVE (state, value) {
    state.isSwitchAccountPopupActive = value
  },
  // VxAutoSuggest

  UPDATE_STARRED_PAGE (state, payload) {

    // find item index in search list state
    const index = state.navbarSearchAndPinList['pages'].data.findIndex((item) => item.url === payload.url)

    // update the main list
    state.navbarSearchAndPinList['pages'].data[index].is_bookmarked = payload.val

    // if val is true add it to starred else remove
    if (payload.val) {
      state.starredPages.push(state.navbarSearchAndPinList['pages'].data[index])
    } else {
      // find item index from starred pages
      const index = state.starredPages.findIndex((item) => item.url === payload.url)

      // remove item using index
      state.starredPages.splice(index, 1)
    }
  },

  // Navbar-Vertical

  ARRANGE_STARRED_PAGES_LIMITED (state, list) {
    const starredPagesMore = state.starredPages.slice(10)
    state.starredPages     = list.concat(starredPagesMore)
  },
  ARRANGE_STARRED_PAGES_MORE (state, list) {
    let downToUp                   = false
    const lastItemInStarredLimited = state.starredPages[10]
    const starredPagesLimited      = state.starredPages.slice(0, 10)
    state.starredPages             = starredPagesLimited.concat(list)

    state.starredPages.slice(0, 10).map((i) => {
      if (list.indexOf(i) > -1) downToUp = true
    })

    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited)
    }
  },


  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////

  TOGGLE_CONTENT_OVERLAY (state, val) { state.bodyOverlay       = val   },
  UPDATE_PRIMARY_COLOR (state, val)   { state.themePrimaryColor = val   },
  UPDATE_THEME (state, val)           { state.theme             = val   },
  UPDATE_WINDOW_WIDTH (state, width)  { state.windowWidth       = width },
  UPDATE_WINDOW_SCROLL_Y (state, val) { state.scrollY           = val   },

  /* UPDATE THEME FOR SPECIFIC ROLE */
  SET_THEME_FOR_USER(state, user){
    let role = user.role
    state.theme = role == 'supplier' || user.role == 'supplier_agent' ? 'rg-semi-dark' : 'light'; 
  }

}

export default mutations

