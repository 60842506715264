import _ from "lodash";

export class ProductService {
  static products = null;
  static getProducts() {
    return [
      {
        id: 1,
        text: 'Paneles solares',
        name: 'photovoltaic',
        cost: 10500,
        category: 'photovoltaic'
      },
      {
        id: 2,
        text: 'Refrigeración comercial',
        name: 'refrigerator',
        cost: 50000,
        category: 'eco-technology'
      },
      {
        id: 3,
        text: 'Aire acondicionado',
        name: 'air_conditioner',
        cost: 11300,
        category: 'eco-technology'
      },
      {
        id: 4,
        text: 'Iluminación',
        name: 'illumination',
        cost: 2000,
        category: 'eco-technology'
      },
      {
        id: 5,
        text: 'Calentador solar',
        name: 'heater',
        cost: 10500,
        category: 'eco-technology'
      }
    ];
  }

  static getAll() {
    if (this.products === null) {
      this.products = this.getProducts();
    }
    return this.products;
  }

  /**
   * @param {number[]} ids
   * @returns {any[]}
   */
  static getByIds(ids) {
    if (!Array.isArray(ids)) {
      throw new Error("El parametro ids debe ser una arreglo de numeros");
    }
    let productSelected = [];
    let products = this.getAll()
    let productIndex = _.keyBy(products, p => p.id)
    for(let id of ids) {
      if (typeof productIndex[id] !== "undefined") {
        productSelected.push(productIndex[id])
      }
    }
    return productSelected;
  }
}
