export function get_extended_session_token(state) {
  return state.extended_access_token;
}

export function check_extended_session(state) {
  return state.has_extended_session === true && state.extended_session_uid !== null;
}

export function get_extended_session_role(state) {
  return state.extended_session_role;
}

export function get_extended_session_user(state) {
  return state.extended_session_user;
}