<template>
  <vs-alert icon-pack="feather" icon="icon-info" class="h-full my-4" color="warning">
    <span>Ha ocurrido un error mientras se obtenían los datos. Lamentamos el inconveniente.
    <a href="#" class="underline bold black" @click="reload">Intenta recargando la página.</a></span>
  </vs-alert>
</template>

<script>
export default {
  methods:{
    reload(){
      window.location.reload();
    },
  }
}
</script>