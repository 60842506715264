import * as moment from 'moment';

export function addSecond(state) {
    state.seconds = state.seconds + 1;
    state.lastDateTime = Date.now()
}

export function resetSecond(state) {
    state.seconds = 0;
}

export function adjustTimerForRefreshAction(state) {
    if (state.lastDateTime !== null) {
        let now = new Date();
        let lastDateTime = new Date(state.lastDateTime);
        let diffSeconds = ((now.getTime() - lastDateTime.getTime()) / 1000);

        state.seconds = parseFloat((state.seconds + diffSeconds).toFixed(2));
    }
};

export function registerNextCloseSession(state, sessionData) {
    let nextCloseSessionDate = moment(sessionData.date).add(parseInt(sessionData.expires_in - 10), 's');
    state.nextCloseSessionDate = nextCloseSessionDate.unix()
}
