export default {
  methods: {
    /**
     * Envio de peticion POST con header de public api token
     * @param {String} uri
     * @param {*} payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    async publicApiPost(uri, payload){
      return await axios.post(uri, payload, { headers: { Authorization: `Bearer ${process.env.VUE_APP_API_APP_TOKEN}`}} );
    },

    /**
     * Envio de peticion GET con header de public api token
     * @param {String} uri
     * @returns {Promise<AxiosResponse<any>>}
     */
    async publicApiGet(uri){
      return await axios.get(uri, { headers: { Authorization: `Bearer ${process.env.VUE_APP_API_APP_TOKEN}`}} );
    },

    /**
     * Envio de peticion PUT con header de public api token
     * @param {String} uri
     * @returns {Promise<AxiosResponse<any>>}
     */
    async publicApiPut(uri, payload){
      return await axios.post(uri, payload, { headers: { Authorization: `Bearer ${process.env.VUE_APP_API_APP_TOKEN}`}} );
    },
    /**
     * Token publico para acceso a recursos de assets
     * @returns {*}
     */
    publicAccessToken(){
      return process.env.VUE_APP_API_APP_TOKEN;
    }
  }
}