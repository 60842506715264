import Vue from 'vue'
import Router from 'vue-router'
// import investorBalancesUpdater from "@/helpers/investorBalancesUpdater";
import auth from './middleware/suppliers-auth';
import verified_auth from './middleware/suppliers-verified-auth';
import in_process_auth from './middleware/suppliers-in-process-auth';
import un_auth from './middleware/un-auth';
import pre_auth from "./middleware/un-auth";
const investorBalancesUpdater = () => import('@/helpers/investorBalancesUpdater');
Vue.use(Router)
const router = new Router({
  mode: "history",
  base: "",
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [{
      // ====== KOF LAYOUT =======
      path: '/programa',
      component: () => import('./layouts/main/MainKOF.vue'),
      children: [{
          path: 'inicio',
          name: 'inicioKOF',
          component: () => import('./views/programs/kof/Inicio'),
          meta: {
            title: 'Inicio',
            middleware: [auth],
            rule: 'isProgram'
          }
        },
        {
          path: '',
          component: () => import('./views/programs/kof/Inicio'),
          meta: {
            title: 'Inicio',
            middleware: [auth],
            rule: 'isProgram'
          }
        },
        {
          path: '/programa/performance',
          name: 'performance',
          component: () => import('./views/programs/kof/PerformanceMetric'),
          meta: {
            title: 'Métricas Operativas',
            middleware: [auth],
            rule: 'isProgram',
            // breadcrumb: [
            //   { title: 'Home', url: '/kof/inicio' },
            //   { title: 'Información de apoyo', active: true }
            // ],
          }
        },
        {
          path: '/programa/avance',
          name: 'progressReport',
          component: () => import('./views/programs/kof/ProgressReport'),
          meta: {
            title: "Avance",
            middleware: [auth],
            rule: 'isProgram'
          }
        },
        {
          path: '/programa/demografico',
          name: 'demographicReport',
          component: () => import('./views/programs/kof/DemographicReport'),
          meta: {
            title: "Demográfico",
            middleware: [auth],
            rule: 'isProgram'
          }
        },
        {
          path: '/programa/cobranza',
          name: 'collectionsReport',
          component: () => import('./views/programs/kof/components/analytics/CollectionsDashboard.vue'),
          meta: {
            title: "Desempeño de cobranza",
            middleware: [auth],
            rule: 'isProgram'
          }
        },
        {
          path: '/programa/efectividad',
          name: 'effectivenessReport',
          component: () => import('./views/programs/kof/components/analytics/EffectivenessDashboard.vue'),
          meta: {
            title: "Reporte de efectividad",
            middleware: [auth],
            rule: 'isProgram'
          }
        },
        {
          path: '/programa/informacion-de-apoyo',
          name: 'informacionDeApoyo',
          component: () => import('./views/programs/kof/Tools'),
          meta: {
            title: 'Información de Apoyo',
            middleware: [auth],
            rule: 'isProgram',
            // breadcrumb: [
            //   { title: 'Home', url: '/kof/inicio' },
            //   { title: 'Información de apoyo', active: true }
            // ],
          }
        },
        {
          path: '/programa/carga-de-prospectos',
          name: 'potentialApplicants',
          component: () => import('./views/programs/kof/potentialApplicants'),
          meta: {
            title: 'Alta de prospectos',
            middleware: [auth],
            rule: 'isProgramAdmin',
            // breadcrumb: [
            //   { title: 'Home', url: '/kof/inicio' },
            //   { title: 'Prospectos', active: true }
            // ],
          }
        },
        {
          path: '/programa/proyectos-en-proceso',
          name: 'projectInProcess',
          component: () => import('./views/programs/kof/ProjectsInProcess'),
          meta: {
            title: 'Proyectos en proceso',
            middleware: [auth],
            rule: 'isProgram',
            // breadcrumb: [
            //   { title: 'Home', url: '/kof/inicio' },
            //   { title: 'Prospectos', active: true }
            // ],
          }
        },
        {
          path: '/programa/lista-de-prospectos',
          name: 'pgmPotentialApplicants',
          component: () => import('./views/programs/kof/PGMPotentialApplicants'),
          meta: {
            title: 'Lista de prospectos',
            middleware: [auth],
            rule: 'isProgram',
            // breadcrumb: [
            //   { title: 'Home', url: '/kof/inicio' },
            //   { title: 'Prospectos', active: true }
            // ],
          }
        },
        {
          path: '/programa/usuarios',
          name: 'usuarios',
          component: () => import('./views/programs/kof/UsersCatalog'),
          meta: {
            title: 'Usuarios',
            middleware: [auth],
            rule: 'isProgram'
          }
        },
        {
          path: '/programa/catalogo-zonas-y-regiones',
          name: 'catalogoDeZonasYRegiones',
          component: () => import('./views/programs/kof/RegionsCatalog'),
          meta: {
            title: 'Zonas y Regiones',
            middleware: [auth],
            rule: 'isProgram'
          }
        },
      ]
    },
    {
      //=====MAIN LAYOUT ====
      path: '/proveedor',
      component: () => import('./layouts/main/MainSupplier.vue'),
      children: [{
          path: '',
          component: () => import('./views/supplier/Inicio'),
          meta: {
            title: 'Inicio',
            middleware: [auth],
            rule: 'isSupplierOrAgent'
          }
        },
        {
          path: 'inicio',
          name: 'inicio',
          component: () => import('./views/supplier/Inicio'),
          meta: {
            title: 'Inicio',
            middleware: [auth],
            rule: 'isSupplierOrAgent'
          }
        },
        {
          path: 'perfil',
          name: 'perfil',
          component: () => import('./views/supplier/profile/ProfilePage'),
          meta: {
            title: 'Perfil',
            middleware: [auth],
            rule: 'isSupplier',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Perfil', active: true }
            ],
          }
        },
        {
          path: 'perfil/editar',
          name: 'perfil-editar',
          component: () => import('./views/supplier/profile/EditProfile'),
          meta: {
            title: 'Editar perfil',
            middleware: [auth],
            rule: 'isSupplier',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Actualizar', active: true }
            ],
          }
        },
        {
          path: 'perfil/notificaciones',
          name: 'perfil-editar-notificaciones',
          component: () => import('./views/supplier/profile/EditProfile'),
          meta: {
            title: 'Editar notificaciones',
            middleware: [auth],
            rule: 'isSupplier',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Actualizar', active: true }
            ],
          },
          props: {
            'tab_index' : 7
          }
        },
        {
          path: 'perfil/acreditaciones/:id',
          name: 'perfilDetalleAcreditacion',
          props: true,
          component: () => import('./views/supplier/AccreditationDetail'),
          meta: {
            title: 'Detalle de acreditación',
            middleware: [auth],
            rule: 'isSupplierOrAgent',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Perfil', url: '/proveedor/perfil/editar' },
              { title: 'Tecnologías', url: '/proveedor/perfil/editar?tab=tecnologias' },
              { title: '', active: true }
            ],
          }
        },
        {
          path: 'perfil/informacion',
          name: 'perfil-informacion',
          component: () => import('./views/supplier/profile/InformationPage'),
          meta: {
            title: 'Perfil',
            middleware: [auth],
            rule: 'isSupplier',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Edit', active: true }
            ],
          }
        },
        {
          path: 'perfil/cuenta',
          name: 'perfil-cuenta',
          component: () => import('./views/supplier/profile/AccountPage'),
          meta: {
            title: 'Cuenta',
            middleware: [auth],
            rule: 'isSupplier',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Mi cuenta', active: true }
            ],
          }
        },
        {
          path: 'perfil/seguridad',
          name: 'perfil-seguridad',
          component: () => import('./views/supplier/profile/SecurityPage'),
          meta: {
            title: 'Seguridad',
            middleware: [auth],
            rule: 'isSupplierOrAgent',
          }
        },
        {
          path: 'perfil/seguridad/contrasena',
          name: 'perfil-seguridad-contrasena',
          component: () => import('./views/supplier/profile/PasswordPage'),
          meta: {
            title: 'Contraseña',
            middleware: [auth],
            rule: 'isSupplier',
          }
        },
        {
          path: 'mis-herramientas',
          name: 'misHerramientas',
          component: () => import('./views/supplier/tools/SupplierTools'),
          meta: {
            title: 'Herramientas',
            middleware: [auth],
            rule: 'isSupplierOrAgent',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Herramientas', active: true }
            ],
          }
        },
        {
          path: 'credito-integradores',
          name: 'integradorHerramientas',
          component: () => import('./views/supplier/tools/SupplierLoanTools'),
          meta: {
            title: 'Crédito para integradores',
            middleware: [auth],
            rule: 'isSupplierOrAgent',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Crédito para integradores', active: true }
            ],
          },
        },
        {
          path: 'credito-integradores/simulador',
          name: 'capitalWorkSimulator',
          component: () => import('./views/supplier/tools/credit-work-simulator/CreditWorkSimulator.vue'),
          meta: {
            title: 'Simulador de crédito para integradores',
            middleware: [auth],
            rule: 'isSupplierOrAgent',
            breadcrumb: [
              {title: 'Home'},
              {title: 'Crédito para integradores'},
              {title: 'Simulador', active: true}
            ],
          }
        },
        {
          path: 'vendedores',
          name: 'vendedores',
          component: () => import('./views/supplier/AgentsPage'),
          meta: {
            title: 'Vendedores',
            middleware: [verified_auth],
            rule: 'isSupplier',
          }
        },
        {
          path: 'solicitantes',
          name: 'solicitantes',
          component: () => import('./views/supplier/ApplicantsPage'),
          meta: {
            title: 'Solicitantes',
            middleware: [verified_auth],
            rule: 'isSupplierOrAgent',
          }
        },
        {
          path: 'acreditaciones',
          name: 'acreditaciones',
          component: () => import('./views/supplier/AccreditationsPage'),
          meta: {
            title: 'Mis tecnologías',
            middleware: [verified_auth],
            rule: 'isSupplier',
          }
        },
        {
          path: 'acreditaciones/:id',
          name: 'detalleAcreditacion',
          props: true,
          component: () => import('./views/supplier/AccreditationDetail'),
          meta: {
            title: 'Detalle de tecnología',
            middleware: [verified_auth],
            rule: 'isSupplier',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mis tecnologías', url: '/proveedor/acreditaciones' },
              { title: 'Detalle', active: true }
            ],
          }
        },
        {
          path: 'simulador-express',
          name: 'simuladorExpress',
          component: () => import('./views/supplier/tools/SimulatorExpressPage'),
          meta: {
            title: 'Simulador express',
            middleware: [auth],
            rule: 'isSupplierOrAgent',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Herramientas', url: '/proveedor/mis-herramientas' },
              { title: 'Simulador Express', active: true }
            ],
          }
        },
        // {
        //   path: 'impacta-mas',
        //   name: 'impactaMas',
        //   component: () => import('./views/supplier/tools/SupplierImpactaMas'),
        //   meta: {
        //     title: 'ImpactaMás',
        //     middleware: [auth],
        //     rule: 'isSupplierOrAgent',
        //     breadcrumb: [
        //       { title: 'Home' },
        //       { title: 'Herramientas', url: '/mis-herramientas' },
        //       { title: 'ImpactaMás', active: true }
        //     ],
        //   }
        // },
        /*{
          path: '/simulador-detallado',
          name: 'simuladorDetallado',
          component: () => import('./views/supplier/tools/SimulatorDetailedPage'),
          meta: {
            title: 'Simulador detallado',
            middleware: [verified_auth],
            rule: 'isSupplierOrAgent',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Herramientas', url: '/mis-herramientas' },
              { title: 'Simulador detallado', active: true }
            ],
          }
        },*/
        {
          path: 'mis-proyectos',
          name: 'misProyectos',
          component: () => import('./views/supplier/projects/ProjectsPage'),
          meta: {
            title: 'Lista de proyectos',
            middleware: [in_process_auth],
            rule: 'isSupplierOrAgent'
          }
        },
        {
          path: 'mis-proyectos-archivados',
          name: 'misProyectosArchivados',
          component: () => import('./views/supplier/projects/ArchivedProjectsPage'),
          meta: {
            title: 'Proyectos archivados',
            middleware: [in_process_auth],
            rule: 'isSupplierOrAgent'
          }
        },
        {
          path: 'crear-proyecto/nuevo',
          name: 'crearProyecto',
          component: () => import('./views/supplier/projects/PreprojectPage'),
          meta: {
            title: 'Crear proyecto',
            middleware: [in_process_auth],
            rule: 'isSupplierOrAgent'
          }
        },
        {
          path: 'crear-proyecto/:id/acuse',
          name: 'crearProyectoAcuse',
          component: () => import('./views/supplier/projects/PreProjectCreatedPage'),
          props: true,
          meta: {
            title: 'Nuevo proyecto',
            middleware: [in_process_auth],
            rule: 'isSupplierOrAgent'
          }
        },
        {
          path: 'proyecto/:id',
          name: 'detalleProyecto',
          component: () => import('./views/supplier/projects/ProjectDetail'),
          props: true,
          meta: {
            title: 'Detalle de proyecto',
            middleware: [in_process_auth],
            rule: 'isSupplierOrAgent',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Listado de proyectos', url: '/proveedor/mis-proyectos' },
              { title: 'Detalle', active: true }
            ],
          }
        },
        {
          path: 'proyecto/:id/fg_location/:fg_id',
          name: 'editarFgLocationProyecto',
          component: () => import('./views/supplier/projects/ProjectTechnologyEditPage'),
          props: true,
          meta: {
            title: 'Ubicación en proyecto',
            middleware: [verified_auth],
            rule: 'isSupplierOrAgent',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Proyecto {id}', url: '/proveedor/proyecto/{id}' },
              { title: 'Tecnologías a financiar', url: '/proveedor/proyecto/{id}?nav=solicitud&tab=tecnologias' },
              { title: 'Detalle de ubicación', active: true }
            ],
          }
        },
        {
          path: 'proyecto/:id/fg_location/:fg_id/datos-tecnicos',
          name: 'tecnicosFgLocationProyecto',
          component: () => import('./views/supplier/projects/ProjectTechnologyTechnicalPage'),
          props: true,
          meta: {
            title: 'Datos técnicos de la tecnología',
            middleware: [verified_auth],
            rule: 'isSupplierOrAgent',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Proyecto {id}', url: '/proveedor/proyecto/{id}' },
              { title: 'Datos técnicos por tecnología', url: '/proveedor/proyecto/{id}?nav=postfondeo&tab=tecnicos' },
              { title: 'Integración de datos', active: true }
            ],
          }
        },
        {
          path: 'proyecto/:id/fg_location/:fg_id/evidencias',
          name: 'evidenciasFgLocationProyecto',
          component: () => import('./views/supplier/projects/ProjectTechnologyEvidencesPage'),
          props: true,
          meta: {
            title: 'Evidencias de la tecnología',
            middleware: [verified_auth],
            rule: 'isSupplierOrAgent',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Proyecto {id}', url: '/proveedor/proyecto/{id}' },
              { title: 'Evidencias por tecnología', url: '/proveedor/proyecto/{id}?nav=postfondeo&tab=evidencias' },
              { title: 'Integración de evidencias', active: true }
            ],
          }
        },
        {
          path: '/proveedor/mis-prospectos',
          name: 'misProspectos',
          component: () => import('./views/supplier/programs/kof/PotentialApplicants.vue'),
          meta: {
            title: 'Lista de prospectos',
            middleware: [verified_auth],
            rule: 'isSupplierOrAgent',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Coca-Cola FEMSA', active: true },
            ]
          }
        },
        {
          path: 'pagos',
          name: 'misPagos',
          component: () => import('./views/supplier/PaymentsPage'),
          props: true,
          meta: {
            title: 'Pagos y facturas',
            middleware: [verified_auth],
            rule: 'isSupplierOrAgent'
          }
        },
        {
          path: 'catalogo',
          name: 'catalogo',
          component: () => import('./views/supplier/SupplierProductsPage'),
          props: true,
          meta: {
            title: 'Catálogo',
            middleware: [verified_auth],
            rule: 'isSupplierOrAgent'
          }
        },
        {
          path: 'proyecto/:id/firma-contrato/:wid',
          name: 'mifielContractSign',
          component: () => import('./views/supplier/projects/MifielSignContract'),
          props: true,
          meta: {
            title: 'Firma de contrato electrónico',
            middleware: [verified_auth],
            rule: 'isSupplier',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Proyecto {id}', url: '/proveedor/proyecto/{id}' },
              { title: 'Contratos', url: '/proveedor/proyecto/{id}?nav=postfondeo&tab=contratos' },
              { title: 'Firmado de contrato electrónico', active: true }
            ],
          }
        },
        /*{
          path: '/ayuda',
          name: 'ayuda',
          component: () => import('./views/supplier/misc/HelpPage'),
          meta: {
            title: 'Ayuda',
            middleware: [auth],
            rule: 'isSupplierOrAgent'
          }
        },
        {
         path: '/tests',
         name: 'test',
         component: () => import("./views/supplier/TestsPage"),
         meta: {
           title: 'Recomendaciones de seguridad',
           middleware: [auth],
           rule: 'isSupplierOrAgent',
         }
        },*/
        {
          path: 'faqs',
          name: 'faqsSuppliers',
          component: () => import('@views/supplier/FaqsSuppliers'),
          meta: {
            title: 'Preguntas frecuentes',
            middleware: [auth],
            rule: 'isSupplierOrAgent'
          }
        },
        {
          path: 'seguridad',
          name: 'seguridadInstaladores',
          component: () => import('./views/investor/security/SecurityTips'),
          meta: {
            title: 'Recomendaciones de seguridad',
            middleware: [auth],
            rule: 'isSupplierOrAgent',
          }
        },
        {
          path: 'res-auth',
          name: 'resAuthSuppliers',
          component: () => import("./views/ResAuth"),
          meta: {
            middleware: [pre_auth],
            rule: 'isPublic'
          }
        },
        {
          path: 'proyecto/:id/fuente_ingresos/:income_source_id/:role',
          name: 'editarIncomeSourcesDeSolicitante',
          component: () => import('./views/supplier/projects/IncomeSourcesEditPage.vue'),
          props: true,
          meta: {
            title: 'Comprobantes de Ingreso',
            middleware: [verified_auth],
            rule: 'isSupplierOrAgent',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Proyecto {id}', url: '/proveedor/proyecto/{id}' },
              { title: 'Ingresos del Solicitante', url: '/proveedor/proyecto/{id}?nav=solicitud&tab=informacion_bancaria' },
              { title: 'Detalle de fuente de ingreso', active: true }
            ],
          }
        },
        {
          path: 'mis-contratos/constancia-conocimiento/',
          name: 'KnowledgeContract',
          component: () => import('./views/supplier/contracts/KnowledgeContract.vue'),
          props: true,
          meta: {
            title: 'Constancia de Conocimiento',
            middleware: [auth],
            rule: 'isSupplier',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mis contratos', url: '/proveedor/perfil/editar' },
              { title: 'Constancia de Conocimiento', active: true }
            ],
          }
        },
        {
          path: 'onboarding/completar-perfil',
          name: 'SupplierOnboardingHomePage',
          component: () => import('./views/supplier/onboarding/SupplierOnboardingHomePage.vue'),
          meta: {
            title: 'Completar perfil',
            middleware: [auth],
            rule: 'isSupplier',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Completar onboarding', active: true }
            ],
          }
        },
      ]
    },
    {
      //=====MAIN LAYOUT APPLICANT ====
      path: '/solicitante',
      component: () => import('./layouts/main/MainApplicant.vue'),
      children: [
        {
          path: 'inicio',
          name: 'inicioSolicitante',
          component: () => import('./views/applicant/Inicio'),
          meta: {
            middleware: [auth],
            rule: 'isApplicant'
          }
        },
        {
          path: '',
          component: () => import('./views/applicant/Inicio'),
          meta: {
            middleware: [auth],
            rule: 'isApplicant'
          }
        },
        {
          path: 'perfil/cuenta',
          component: () => import('./views/applicant/profile/AccountPage'),
          meta: {
            title: 'Cuenta',
            middleware: [auth],
            rule: 'isApplicant',
            breadcrumb: [
              { title: 'Home', url: '/solicitante/inicio' },
              { title: 'Mi cuenta', active: true }
            ],
          }
        },
        {
          path: 'seguridad',
          name: 'seguridadSolicitantes',
          component: () => import('./views/investor/security/SecurityTips'),
          meta: {
            title: 'Recomendaciones de seguridad',
            middleware: [auth],
            rule: 'isApplicant',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mi perfil', url: '/solicitante/perfil' },
              { title: 'Sugerencias de seguridad', active: true }
            ],
          }
        },
        {
          path: 'perfil/seguridad',
          name: 'seguridadSolicitante',
          component: () => import('./views/applicant/security/SecurityPage'),
          meta: {
            title: 'Seguridad',
            middleware: [auth],
            rule: 'isApplicant',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mi Perfil', url: '/solicitante/perfil' },
              { title: 'Seguridad', active: true }
            ],
          }
        },
        {
          path: 'perfil/seguridad/2fa',
          name: 'auth2FaSolicitante',
          component: () => import('./views/applicant/security/Auth2FaPage'),
          meta: {
            title: 'Mi Cuenta',
            middleware: [auth],
            rule: 'isApplicant',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Seguridad', url: '/solicitante/perfil' },
              { title: 'Segundo Factor de Autenticación', active: true }
            ],
          }
        },
        {
          path: 'perfil/seguridad/contrasena',
          name: 'passwordSolicitante',
          component: () => import('./views/applicant/security/PasswordPage'),
          meta: {
            title: 'Mi Cuenta',
            middleware: [auth],
            rule: 'isApplicant',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mi perfil', url: '/solicitante/perfil' },
              { title: 'Contraseña', active: true }
            ],
          }
        },
        {
          path: 'perfil',
          name: 'perfilSolicitante',
          component: () => import('./views/applicant/profile/ProfilePage'),
          meta: {
            middleware: [auth],
            rule: 'isApplicant',
          }
        },
        {
          path: 'perfil/notificaciones',
          name: 'perfilSolicitanteNotificaciones',
          component: () => import('./views/applicant/profile/ProfilePage'),
          meta: {
            middleware: [auth],
            rule: 'isApplicant',
          },
          props: {
            'tab_index': 4
          }
        },
        {
          path: 'perfil/editar-general',
          name: 'perfilSolicitanteGeneral',
          component: () => import('./views/applicant/profile/EditApplicantProfile'),
          meta: {
            title: 'Mi Perfil',
            middleware: [auth],
            rule: 'isApplicant',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mi información', url: '/solicitante/perfil' },
              { title: 'Datos Generales', active: true }
            ],
          }
        },
        {
          path: 'editar-perfil/perfil-fiscal',
          name: 'perfilFiscalSolicitante',
          component: () => import('./views/applicant/profile/ApplicantTaxProfile'),
          meta: {
            title: 'Perfil Fiscal',
            middleware: [auth],
            rule: 'isApplicant',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mi información', url: '/solicitante/perfil' },
              { title: 'Perfil Fiscal', active: true }
            ],
          }
        },
        {
          path: 'facturacion/csd',
          name: 'csd',
          component: () => import('./views/applicant/csds/CsdsPage'),
          meta: {
            title: 'Carga de CSD',
            middleware: [auth],
            rule: 'isApplicant',
            breadcrumb: [
              { title: 'Home' },
              { title: 'CSD', active: true }
            ],
          }
        },
        {
          path: 'facturacion/retenciones',
          name: 'csd',
          component: () => import('./views/applicant/monthly-retentions/MonthlyRetentionPage'),
          meta: {
            title: 'Facturas de retención',
            middleware: [auth],
            rule: 'isApplicant',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Retenciones', active: true }
            ],
          }
        },
        {
          path: 'editar-perfil/sobre-ti',
          name: 'perfilSolicitanteAbout',
          component: () => import('./views/applicant/profile/AboutYou'),
          meta: {
            title: 'Sobre ti',
            middleware: [auth],
            rule: 'isApplicant',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mi información', url: '/solicitante/perfil' },
              { title: 'Sobre ti', active: true }
            ],
          }
        },
        // {
        //   path: 'beneficiarios',
        //   name: 'misBeneficiarios',
        //   component: () => import('./views/applicant/BeneficiariesPage'),
        //   meta: {
        //     middleware: [auth],
        //     rule: 'isApplicant',
        //   }
        // },
        // {
        //   path: 'beneficiarios/nuevo',
        //   name: 'nuevoBeneficiario',
        //   component: () => import('./views/applicant/NewBeneficiaryPage'),
        //   meta: {
        //     middleware: [auth],
        //     rule: 'isApplicant'
        //   }
        // },
        // {
        //   path: 'beneficiarios/:id',
        //   name: 'infoBeneficiario',
        //   component: () => import('./views/applicant/ShowBeneficiary'),
        //   props: true,
        //   meta: {
        //     middleware: [auth],
        //     rule: 'isApplicant'
        //   }
        // },
        // {
        //   path: 'beneficiarios/:id/edit',
        //   name: 'editBeneficiario',
        //   component: () => import('./views/applicant/EditBeneficiaryPage'),
        //   props: true,
        //   meta: {
        //     middleware: [auth],
        //     rule: 'isApplicant'
        //   }
        // },
        {
          path: 'kyc',
          name: 'kycSolicitante',
          component: () => import('./views/applicant/KycPage'),
          meta: {
            middleware: [auth],
            rule: 'isApplicant'
          }
        },
        {
          path: 'kycPerson',
          name: 'kycSolicitantePersona',
          component: () => import('./views/applicant/KycPersonPage'),
          meta: {
            middleware: [auth],
            rule: 'isApplicant'
          }
        },
        {
          path: 'kycProveedor',
          name: 'kycSolicitanteProveedor',
          component: () => import('./views/applicant/KycProviderPage'),
          meta: {
            middleware: [auth],
            rule: 'isApplicant'
          }
        },
        {
          path: 'kycPropietario',
          name: 'kycSolicitantePropietario',
          component: () => import('./views/applicant/KycOwnerPage'),
          meta: {
            middleware: [auth],
            rule: 'isApplicant'
          }
        },
        {
          path: 'referencias',
          name: 'referenciasSolicitante',
          component: () => import('./views/applicant/References'),
          meta: {
            middleware: [auth],
            rule: 'isApplicant'
          }
        },
        {
          path: 'movimientos',
          name: 'movimientosSolicitante',
          component: () => import('./views/applicant/Movements'),
          meta: {
            middleware: [auth],
            rule: 'isApplicant'
          }
        },
        {
          path: 'realizar-pagos',
          name: 'realizarPagosSolicitante',
          component: () => import('./views/applicant/PaymentOptionsPage'),
          meta: {
            middleware: [auth],
            rule: 'isApplicant',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Realizar pagos', active: true }
            ],
          }
        },
        {
          path: 'pagos-automaticos',
          name: 'pagoAutomaticoSolicitante',
          component: () => import('./views/applicant/AutomaticPaymentsPage'),
          meta: {
            middleware: [auth],
            rule: 'isApplicant',
            parent: 'realizarPagosSolicitante',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Realizar pagos', url: '/solicitante/realizar-pagos' },
              { title: 'Activar pago automático', active: true }
            ],
          },
        },
        // {
        //   path: 'credito',
        //   name: 'creditoSolicitante',
        //   component: () => import('./views/applicant/projects/ApplicantProjectDetail'),
        //   meta: {
        //     middleware: [auth],
        //     rule: 'isApplicant'
        //   }
        // },
        {
          path: 'crear-proyecto/nuevo',
          name: 'crearProyectoSolicitante',
          component: () => import('./views/applicant/projects/ApplicantPreprojectPage'),
          meta: {
            title: 'Crear proyecto',
            middleware: [auth],
            rule: 'isApplicant'
          }
        },
        // {
        //   path: 'mis-proyectos',
        //   name: 'misProyectosSolicitante',
        //   component: () => import('./views/applicant/projects/ApplicantProjectsPage'),
        //   meta: {
        //     title: 'Lista de proyectos',
        //     middleware: [auth],
        //     rule: 'isApplicant'
        //   }
        // },
        {
          path: 'credito/:id',
          name: 'detalleCreditoSolicitante',
          component: () => import('./views/applicant/projects/ApplicantProjectDetail'),
          props: true,
          meta: {
            title: 'Detalle de crédito',
            middleware: [auth],
            rule: 'isApplicant'
          }
        },
        // {
        //   path: 'proyecto/:id/fg_location/:fg_id',
        //   name: 'editarFgLocationProyectoSolicitante',
        //   component: () => import('./views/applicant/projects/ApplicantProjectTechnologyEditPage'),
        //   props: true,
        //   meta: {
        //     title: 'Ubicación en proyecto',
        //     middleware: [verified_auth],
        //     rule: 'isApplicant',
        //     // breadcrumb: [
        //     //   { title: 'Home' },
        //     //   { title: 'Proyecto {id}', url: '/proyecto/{id}' },
        //     //   { title: 'Tecnologías a financiar', url: '/proyecto/{id}?nav=solicitud&tab=tecnologias' },
        //     //   { title: 'Detalle de ubicación', active: true }
        //     // ],
        //   }
        // },
        {
          path: 'faqs',
          name: 'faqsApplicants',
          component: () => import('@views/applicant/FaqsApplicants'),
          meta: {
            title: 'Preguntas frecuentes',
            middleware: [auth],
            rule: 'isApplicant'
          }
        },
        {
          path: 'estados-cuenta',
          name: 'estadosCuentaSolicitante',
          component: () => import('./views/applicant/StatementsPage'),
          meta: {
            title: 'Estados de cuenta',
            middleware: [auth],
            rule: 'isApplicant'
          }
        },
        {
          path: 'encuesta/:pid',
          name: 'encuestaSatisfaccionSolicitante',
          component: () => import('./views/applicant/projects/ApplicantProjectSurveyFeedback'),
          props: true,
          meta: {
            title: 'Encuesta de satisfacción',
            middleware: [auth],
            rule: 'isApplicant'
          }
        },
        {
          path: 'contratos-pendientes/:pid',
          name: 'contratosPendientesSolicitante',
          component: () => import('./views/applicant/ApplicantPendingContracts'),
          props: true,
          meta: {
            title: 'Contratos por firmar',
            middleware: [auth],
            rule: 'isApplicant'
          }
        },
        {
          path: 'contratos',
          name: 'contratosSolicitante',
          component: () => import('./views/applicant/contracts/ContractsPage'),
          meta: {
            title: 'Contratos',
            middleware: [auth],
            rule: 'isApplicant'
          }
        },
        // --------- NUEVO FLUJO DE SOLICITUDES ------------
        {
          path: 'simulador-de-credito/:loanTypeProp',
          name: 'loanRequest',
          component: () => import('./views/applicant/loan-requests/LoanRequestPage.vue'),
          props: true,
          meta: {
            title: 'Solicitud de crédito',
            middleware: [auth],
            rule: 'isApplicant'
          }
        },
        {
          path: 'solicitud-de-credito/:id',
          name: 'applicantLoanDetail',
          component: () => import('./views/applicant/loan-requests/LoanOnboardingStepPage.vue'),
          props: true,
          meta: {
            title: 'Solicitud de crédito',
            middleware: [auth],
            rule: 'isApplicant',
            // breadcrumb: [
            //   { title: 'Home' },
            //   // { title: 'Listado de proyectos', url: '/proveedor/mis-proyectos' },
            //   { title: 'Detalle', active: true }
            // ],
          }
        },
        // --------- NUEVO FLUJO DE SOLICITUDES ------------
      ]
    },
    {
      //=====MAIN LAYOUT INVESTOR ====
      path: '/inversionista',
      component: () => import('./layouts/main/MainInvestor.vue'),
      children: [{
          path: 'inicio',
          name: 'inicioInversionista',
          component: () => import('./views/investor/Inicio'),
          meta: {
            middleware: [auth],
            rule: 'isInvestor'
          }
        },
        {
          path: '',
          component: () => import('./views/investor/Inicio'),
          meta: {
            middleware: [auth],
            rule: 'isInvestor'
          }
        },
        {
          path: 'perfil',
          name: 'perfilInversionista',
          component: () => import('./views/investor/profile/ProfileRootPage'),
          meta: {
            title: 'Mi Perfil',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mi perfil', active: true }
            ],
          }
        },
        {
          path: 'perfil/:tab',
          name: 'perfilInversionistaTab',
          props: true,
          component: () => import('./views/investor/profile/ProfilePage'),
          meta: {
            title: 'Mi Perfil',
            middleware: [auth],
            rule: 'isInvestor'
          }
        },
        {
          path: 'editar-perfil/sobre-ti',
          name: 'perfilInversionistaAbout',
          component: () => import('./views/investor/profile/AboutYou'),
          meta: {
            title: 'Sobre ti',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mi perfil', url: '/inversionista/perfil' },
              { title: 'Sobre ti', active: true }
            ],
          }
        },
        {
          path: 'editar-perfil/banco',
          name: 'perfilInversionistaBank',
          component: () => import('./views/investor/profile/InvestorBank'),
          meta: {
            title: 'Banco',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mi perfil', url: '/inversionista/perfil' },
              { title: 'Banco', active: true }
            ],
          }
        },
        {
          path: 'editar-perfil/contacto',
          name: 'perfilInversionistaContact',
          component: () => import('./views/investor/profile/InvestorContact'),
          meta: {
            title: 'Contacto',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mi perfil', url: '/inversionista/perfil' },
              { title: 'Contacto', active: true }
            ],
          }
        },
        {
          path: 'editar-perfil/telefono',
          name: 'perfilInversionistaPhone',
          component: () => import('./views/investor/profile/InvestorPhone'),
          meta: {
            title: 'Teléfono',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mi perfil', url: '/inversionista/perfil' },
              { title: 'Teléfono', active: true }
            ],
          }
        },
        {
          path: 'editar-perfil/beneficiarios',
          name: 'perfilInversionistaBeneficiaries',
          component: () => import('./views/investor/profile/BeneficiariesPage'),
          meta: {
            title: 'Beneficiarios',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mi perfil', url: '/inversionista/perfil' },
              { title: 'Beneficiarios', active: true }
            ],
          }
        },
        {
          path: 'beneficiarios/nuevo',
          name: 'nuevoBeneficiarioInversionista',
          component: () => import('./views/applicant/NewBeneficiaryPage'),
          meta: {
            middleware: [auth],
            rule: 'isInvestor'
          }
        },
        {
          path: 'kyc',
          name: 'kycInversionista',
          component: () => import('./views/investor/profile/KycPage'),
          meta: {
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Principal', url: '/inversionista/perfil' },
              { title: 'Queremos conocerte', active: true }
            ],
          }
        },
        {
          path: 'editar-perfil/perfil-fiscal',
          name: 'perfilFiscalInversionista',
          component: () => import('./views/investor/profile/InvestorTaxProfile'),
          meta: {
            title: 'Perfil Fiscal',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mi perfil', url: '/inversionista/perfil' },
              { title: 'Perfil Fiscal', active: true }
            ],
          }
        },
        {
          path: 'kycPerson',
          name: 'kycInvestorPersona',
          component: () => import('./views/investor/profile/KycPersonPage'),
          meta: {
            middleware: [auth],
            rule: 'isInvestor'
          }
        },
        {
          path: 'kycProveedor',
          name: 'kycInvestorProveedor',
          component: () => import('./views/investor/profile/KycProviderPage'),
          meta: {
            middleware: [auth],
            rule: 'isInvestor'
          }
        },
        {
          path: 'kycPropietario',
          name: 'kycInvestorPropietario',
          component: () => import('./views/investor/profile/KycOwnerPage'),
          meta: {
            middleware: [auth],
            rule: 'isInvestor'
          }
        },
        {
          path: 'perfil/contratos/:type',
          name: 'infoContratoInversionista',
          props: true,
          component: () => import('./views/investor/contracts/ContractInfoPage'),
          meta: {
            title: 'Mis contratos',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Contratos', url: '/inversionista/perfil/contratos' },
              { title: 'Detalle', active: true }
            ],
          }
        },
        {
          path: 'consentimientos',
          name: 'consentimientosInversionista',
          component: () => import('./views/investor/contracts/ConsentsPage'),
          meta: {
            title: 'Mis consentimientos',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Contratos', url: '/inversionista/perfil/contratos' },
              { title: 'Consentimientos', active: true }
            ],
          }
        },
        {
          path: 'perfil/seguridad',
          name: 'seguridadInversionista',
          component: () => import('./views/investor/security/SecurityPage'),
          meta: {
            title: 'Seguridad',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mi Perfil', url: '/inversionista/perfil' },
              { title: 'Seguridad', active: true }
            ],
          }
        },
        {
          path: 'perfil/seguridad/2fa',
          name: 'auth2FaInversionista',
          component: () => import('./views/investor/security/Auth2FaPage'),
          meta: {
            title: 'Mi Cuenta',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Seguridad', url: '/inversionista/perfil/seguridad' },
              { title: 'Segundo Factor de Autenticación', active: true }
            ],
          }
        },
        {
          path: 'perfil/seguridad/contrasena',
          name: 'passwordInversionista',
          component: () => import('./views/investor/security/PasswordPage'),
          meta: {
            title: 'Mi Cuenta',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Seguridad', url: '/inversionista/perfil/seguridad' },
              { title: 'Contraseña', active: true }
            ],
          }
        },
        {
          path: 'abonar-fondos',
          name: 'abonarFondos',
          component: () => import('./views/investor/funds/PayFunds'),
          meta: {
            title: 'Abonar fondos',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Abonar fondos', active: true }
            ],
          }
        },
        {
          path: 'oportunidades',
          name: 'oportunidades',
          component: () => import('@views/investor/funds/Opportunities'),
          meta: {
            title: 'Invertir',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Proyectos semilla', active: true }
            ],
          }
        },
        {
          path: 'oportunidad/:id',
          name: 'oportunidadInversion',
          props: true,
          component: () => import('@views/investor/funds/LoanDetail'),
          meta: {
            title: 'Proyecto',
            middleware: [auth],
            rule: 'isInvestor',
            parent: 'oportunidades',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Proyectos semilla', url: '/inversionista/oportunidades' },
              { title: 'Detalle', active: true }
            ],
          }
        },
        {
          path: 'retirar-fondos',
          name: 'retirarFondos',
          component: () => import('./views/investor/funds/WithdrawFunds'),
          meta: {
            title: 'Retirar fondos',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Retirar fondos', active: true }
            ],
          }
        },
        {
          path: 'portafolio',
          name: 'miPortafolio',
          component: () => import('@views/investor/investments/Portfolio'),
          meta: {
            title: 'Mi portafolio',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mi portafolio', active: true }
            ],
          }
        },
        {
          path: 'mis-proyectos',
          name: 'misProyectosInversionista',
          component: () => import('./views/investor/investments/ProjectsPage'),
          meta: {
            title: 'Mis proyectos',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mis proyectos', active: true }
            ],
          }
        },
        {
          path: 'mis-proyectos/:id',
          name: 'proyectoInversionista',
          props: true,
          component: () => import('@views/investor/investments/ProjectInvestedDetail'),
          meta: {
            title: 'Mi proyecto',
            middleware: [auth],
            rule: 'isInvestor',
            parent: 'misProyectosInversionista',
            // breadcrumb: [
            //   { title: 'Home' },
            //   { title: 'Mis proyectos', url: '/inversionista/mis-proyectos' },
            //   { title: 'Detalle', active: true }
            // ],
          }
        },
        {
          path: 'mis-movimientos',
          name: 'misMovimientosInversionista',
          component: () => import('./views/investor/investments/MovementsPage'),
          meta: {
            title: 'Mis movimientos',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mis movimientos', active: true }
            ],
          }
        },
        {
          path: 'estados-cuenta',
          name: 'estadosCuentaInversionista',
          component: () => import('./views/investor/investments/StatementsPage'),
          meta: {
            title: 'Mis movimientos',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Estados de cuenta', active: true }
            ],
          }
        },
        {
          path: 'redferidos',
          name: 'redferidos',
          component: () => import('./views/investor/RedferidosPage'),
          meta: {
            title: 'Redferidos',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Redferidos', active: true }
            ],
          }
        },
        // TODO-DELETE-IA: 31/08/2023
        // {
        //   path: 'impacta-mas',
        //   name: 'impactaMasInversionista',
        //   component: () => import('./views/investor/ImpactaMas'),
        //   meta: {
        //     title: 'ImpactaMás',
        //     middleware: [auth],
        //     rule: 'isInvestor',
        //     breadcrumb: [
        //       { title: 'Home' },
        //       { title: 'ImpactaMás', active: true }
        //     ],
        //   }
        // },
        {
          path: 'plan-de-inversion',
          name: 'planesInversion',
          component: () => import('./views/investor/investment-plans/InvestmentPlansMainPageV2'),
          meta: {
            title: 'Planes de inversión',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Planes de inversión', active: true }
            ],
          }
        },
        {
          path: 'opcional-abono-recurrente',
          name: 'opcionalAbonoRecurrente',
          component: () => import('./views/investor/investments/AskAutomaticInvestment'),
          meta: {
            title: 'Abono Recurrente',
            middleware: [auth],
            rule: 'isInvestor',
            parent: 'planesInversion',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Planes de inversión', url: '/inversionista/plan-de-inversion' },
              { title: 'Abono Recurrente', active: true }
            ],
          }
        },
        {
          path: 'abono-recurrente',
          name: 'abonoRecurrente',
          component: () => import('./views/investor/investments/AutomaticInvestment'),
          meta: {
            title: 'Abono Recurrente',
            middleware: [auth],
            rule: 'isInvestor',
            parent: 'planesInversion',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Planes de inversión', url: '/inversionista/plan-de-inversion' },
              { title: 'Abono Recurrente', active: true }
            ],
          }
        },
        // Se quita esta pantalla por flujo planes de inversion TODO-DELETE-IA 09/10/23
        // {
        //   path: 'criterios-inversion',
        //   name: 'criteriosInversion',
        //   component: () => import('./views/investor/investments/InvestmentsPrefs'),
        //   meta: {
        //     title: 'Criterios de inversión',
        //     middleware: [auth],
        //     rule: 'isInvestor',
        //     parent: 'planesInversion',
        //     breadcrumb: [
        //       { title: 'Home' },
        //       { title: 'Planes de inversión', url: '/inversionista/plan-de-inversion' },
        //       { title: 'Criterios de inversión', active: true }
        //     ],
        //   }
        // },
        {
          path: 'ingreso-pasivo',
          name: 'ingresoPasivo',
          component: () => import('./views/investor/investments/PassiveIncome'),
          meta: {
            title: 'Ingreso Pasivo',
            middleware: [auth],
            rule: 'isInvestor',
            parent: 'planesInversion',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Planes de inversión', url: '/inversionista/plan-de-inversion' },
              { title: 'Ingreso Pasivo', active: true }
            ],
          }
        },
        {
          path: 'opcional-retiro-recurrente',
          name: 'opcionalRetiroRecurrente',
          component: () => import('./views/investor/investment-plans/AskRecurrentWithdrawal'),
          meta: {
            title: 'Retiro Recurrente',
            middleware: [auth],
            rule: 'isInvestor',
            parent: 'planesInversion',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Planes de inversión', url: '/inversionista/plan-de-inversion' },
              { title: 'Retiro Recurrente', active: true }
            ],
          }
        },
        {
          path: 'retiro-recurrente',
          name: 'retiroRecurrente',
          component: () => import('./views/investor/investment-plans/RecurrentWithdrawal'),
          meta: {
            title: 'Retiro Recurrente',
            middleware: [auth],
            rule: 'isInvestor',
            parent: 'planesInversion',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Planes de inversión', url: '/inversionista/plan-de-inversion' },
              { title: 'Retiro Recurrente', active: true }
            ],
          }
        },
        {
          path: 'contratos',
          name: 'contratosInversionista',
          component: () => import('./views/investor/contracts/ContractsPage'),
          meta: {
            title: 'Contratos',
            middleware: [auth],
            rule: 'isInvestor'
          }
        },
        {
          path: 'seguridad',
          name: 'seguridadInversionistas',
          component: () => import('./views/investor/security/SecurityTips'),
          meta: {
            title: 'Recomendaciones de seguridad',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Mi perfil', url: '/inversionista/perfil' },
              { title: 'Sugerencias de seguridad', active: true }
            ],
          }
        },
        {
          path: 'faqs',
          name: 'faqsInvestors',
          component: () => import('@views/investor/FaqsInvestors'),
          meta: {
            title: 'Preguntas frecuentes',
            middleware: [auth],
            rule: 'isUser',
            breadcrumb: [
              { title: 'Home' },
              { title: '¿Tienes dudas?', active: true }
            ],
          }
        },
        {
          path: 'recuperados',
          name: 'recoveredProjects',
          component: () => import('./views/share/projects_resale/RecoveredProjects'),
          meta: {
            title: 'Proyectos en reventa',
            middleware: [auth],
            rule: 'isUser'
          }
        },
        {
          path: 'recuperados/:id',
          name: 'recoverdProjectDetail',
          props: true,
          component: () => import('./views/share/projects_resale/RecoveredProjectDetail'),
          meta: {
            title: 'Detalle de proyecto',
            middleware: [auth],
            rule: 'isUser',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Sistemas recuperados', url: '/inversionista/recuperados' },
              { title: 'Detalle', active: true }
            ],
          }
        },
        {
          path: 'aumentar-limite',
          name: 'incrementInvestorContractLevel',
          props: true,
          component: () => import('@views/investor/IncrementInvestorContractLevel'),
          meta: {
            title: 'Aumenta tu límite',
            middleware: [auth],
            rule: 'isInvestor',
            breadcrumb: [
              { title: 'Home' },
              { title: 'Abonar fondos', url: '/inversionista/abonar-fondos' },
              { title: 'Aumentar límite', active: true }
            ],
          }
        },
        /*{
          path: 'pruebas',
          name: 'pruebas',
          component: () => import('@views/investor/Pruebas'),
          meta: {
            title: 'Preguntas frecuentes',
            middleware: [auth],
            rule: 'isUser'
          }
        },*/
      ]
    },

    {
      //=====MAIN LAYOUT INVESTOR ONBOARDING ====
      path: '/inversionista-onboarding',
      component: () => import('./layouts/public/InvestorOnboardingLayout.vue'),
      meta: {
        middleware: [auth],
        rule: 'isInvestor'
      },
      children: [{
        path: '',
        name: 'inversionistaOnboarding',
        component: () => import("./views/investor/InvestorOnboardingStepPage"),
        meta: {
          middleware: [auth],
          rule: 'isPublic'
        }
      }]
    },
    {
      //=====MAIN LAYOUT SUPPLIER ONBOARDING ====
      path: '/instalador-onboarding',
      component: () => import('./layouts/public/SupplierOnboardingLayout.vue'),
      meta: {
        middleware: [auth],
        rule: 'isSupplier'
      },
      children: [
        {
          path: '',
          name: 'supplierOnboarding',
          component: () => import("./views/supplier/onboarding/SupplierOnboardingStepPage.vue"),
          meta: {
            middleware: [auth],
            rule: 'isPublic'
          }
        },
        {
          path: '/agente-onboarding',
          name: 'supplierAgentOnboarding',
          component: () => import("./views/supplier/onboarding/SupplierOnboardingAgentsPage.vue"),
          meta: {
            middleware: [auth],
            rule: 'isPublic'
          }
        },
      ]
    },
    {
      //=====MAIN LAYOUT APPLICANT ONBOARDING ====
      path: '/solicitante-onboarding',
      component: () => import('./layouts/public/ApplicantOnboardingLayout.vue'),
      meta: {
        middleware: [auth],
        rule: 'isApplicant'
      },
      children: [
        {
          path: '',
          name: 'applicantOnboarding',
          component: () => import("./views/applicant/onboarding/ApplicantOnboardingStepPage.vue"),
          meta: {
            middleware: [auth],
            rule: 'isPublic'
          }
        },
      ]
    },
    {
      //===== REGISTER LAYOUT ====
      path: '/',
      component: () => import('./layouts/public/RegisterLayout.vue'),
      children: [{
          path: '',
          name: 'home',
          component: () => import("./views/Home"),
          meta: {
            middleware: [auth],
            rule: 'isPublic'
          }
        },
        {
          path: 'res-auth',
          name: 'resAuth',
          component: () => import("./views/ResAuth"),
          meta: {
            middleware: [pre_auth],
            rule: 'isPublic'
          }
        },
        {
          path: 'login',
          name: 'login',
          component: () => import("./views/LoginPage"),
          meta: {
            title: 'Iniciar sesión',
            middleware: [un_auth],
            rule: 'isPublic'
          }
        },
        {
          path: 'logout',
          name: 'investorsLogout',
          component: () => import("./views/LogoutPage"),
          meta: {
            title: 'RedGirasol',
            middleware: [un_auth],
            rule: 'isPublic'
          }
        },
        {
          path: 'session-logout/:reason',
          name: 'sessionLogout',
          component: () => import("./views/SessionLogout"),
          props: true,
          meta: {
            title: 'Sesión finalizada',
            middleware: [un_auth],
            rule: 'isPublic'
          }
        },
        {
          path: '/firma-de-contratos',
          name: 'Firma de contratos',
          component: () => import("./views/share/contracts/MiFiel/SignMiFielContractsLanding"),
          meta: {
            title: 'Firma de contratos',
            middleware: [un_auth],
            rule: 'isPublic'
          }
        },
        {
          path: '/obligado-solidario/onboarding',
          name: 'guarantee-onboarding',
          component: () => import("./views/applicant/loan-requests/guarantee-onboarding/GuaranteeOnboardingStepPage.vue"),
          meta: {
            title: 'Registro de obligado solidario',
            middleware: [un_auth],
            rule: 'isPublic'
          }
        },
        {
          path: '/obligado-solidario/verificacion-identidad',
          name: 'os-identity-verification',
          component: () => import("./views/applicant/loan-requests/guarantee-onboarding/GuaranteeOnboardingStepPage.vue"),
          meta: {
            title: 'Registro de obligado solidario',
            middleware: [un_auth],
            rule: 'isPublic'
          }
        },
        {
          path: '/obligado-solidario/consulta-cdc',
          name: 'os-cdc-request',
          component: () => import("./views/applicant/loan-requests/guarantee-onboarding/GuaranteeOnboardingStepPage.vue"),
          meta: {
            title: 'Registro de obligado solidario',
            middleware: [un_auth],
            rule: 'isPublic'
          }
        },
        {
          path: '/solicitante/:client_id/verificacion-identidad',
          name: 'Verificación de identidad',
          component: () => import("./views/applicant/profile/IdentityVerificationPage.vue"),
          props: true,
          meta: {
            title: 'Verificación de identidad',
            middleware: [un_auth],
            rule: 'isPublic'
          }
        },
        // {
        //   path: 'info-registro',
        //   name: 'infoRegistro',
        //   component: () => import("./views/register/RegisterSelectionPage"),
        //   meta: {
        //     title: 'Registro',
        //     middleware: [un_auth],
        //     rule: 'isPublic'
        //   }
        // },
        {
          path: 'registro/:type',
          name: 'nuevoRegistro',
          component: () => import("./views/register/RegisterFormPage"),
          props: true,
          meta: {
            title: 'Registrarme',
            middleware: [un_auth],
            rule: 'isPublic'
          }
        },
        {
          path: 'r/:code',
          name: 'nuevoRegistroRedferido',
          component: () => import("./views/register/RegisterFromReferalPage"),
          props: true,
          meta: {
            title: 'Registrarme',
            middleware: [un_auth],
            rule: 'isPublic'
          }
        },
        {
          path: 'solicitar-activacion',
          name: 'requestActivationEmail',
          component: () => import("./views/RequestActivationEmailPage"),
          meta: {
            title: 'Solicitar correo de activación',
            middleware: [un_auth],
            rule: 'isPublic'
          }
        },
        {
          path: 'preauth/:token',
          name: 'preauth',
          props: true,
          component: () => import("./views/PreAuth"),
          meta: {
            middleware: [un_auth],
            rule: 'isPublic'
          }
        },
        {
          path: 'password/reset',
          name: 'recuperarPassword',
          component: () => import("./views/PasswordResetPage"),
          meta: {
            title: 'Recupera tu contraseña',
            middleware: [un_auth],
            rule: 'isPublic'
          }
        },
        {
          path: 'password/reset/:token',
          name: 'setNewPassword',
          props: true,
          component: () => import("./views/PasswordChangePage"),
          meta: {
            title: 'Recupera tu contraseña',
            middleware: [un_auth],
            rule: 'isPublic'
          }
        },
        {
          path: 'email-auth/:token',
          name: 'confirmEmailChange',
          props: true,
          component: () => import("./views/EmailChangeToken"),
          meta: {
            title: 'Cambio de correo',
            rule: 'isPublic',
            skipPostCheck: true,
          }
        },
        {
          path: 'encuesta',
          name: 'applicantSurveyFeedback',
          component: () => import("./views/ApplicantSurveyFeedback"),
          meta: {
            title: 'Encuesta de satisfacción',
            rule: 'isPublic',
          }
        },
        {
          path: 'activar-nuevo-vendedor/:token',
          name: 'activateAgentAccount',
          props: true,
          component: () => import("./views/supplier/agents/ActivateAgentAccount"),
          meta: {
            title: 'Activación de cuenta de vendedor',
            middleware: [un_auth],
            rule: 'isPublic',
          }
        },
        {
          path: '/solicitante/crear/:token',
          name: 'applicantCreateAccount',
          props: true,
          component: () => import("./views/applicant/CreateAccount"),
          meta: {
            title: 'Crear cuenta',
            middleware: [un_auth],
            rule: 'isPublic'
          }
        },
        {
          path: '/tarjetas/:external_id',
          name: 'listCardsPage',
          props: true,
          component: () => import("./views/applicant/ListCardsPage"),
          meta: {
            title: 'Tarjetas registradas',
            middleware: [un_auth],
            rule: 'isPublic'
          }
        },
      ]
    },
    {
      path: '/faqs',
      name: 'faqs',
      component: () => import("./views/GeneralFaqs"),
      meta: {
        middleware: [auth],
        rule: 'isPublic'
      }
    },
    {
      path: '/seguridad',
      name: 'seguridad',
      component: () => import("./views/GeneralSecurityTips"),
      meta: {
        middleware: [auth],
        rule: 'isPublic'
      }
    },
    /*{
      path: '/tests',
      name: 'test2',
      component: () => import("./views/supplier/TestsPage"),
      meta: {
        middleware: [un_auth],
        rule: 'isPublic'
      }
    },*/
    {
      path: '/error',
      name: 'notAuth',
      component: () => import("./views/NotAuthPage"),
      meta: {
        middleware: null,
        rule: 'isPublic'
      }
    },
    {
      path: '*',
      name: 'notFound',
      component: () => import("./views/NotFoundPage"),
      meta: {
        title: 'Página no encontrada',
        middleware: null,
        rule: 'isPublic'
      }
    },
  ]
})


// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
	const subsequentMiddleware = middleware[index];
	// If no subsequent Middleware exists,
	// the default `next()` callback is returned.
	if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({
      ...context,
      next: nextMiddleware
    });
  };
}

router.afterEach((to, from) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  // update balances for investors (only if requiered)
  if (!to.meta.skipPostCheck) {
    investorBalancesUpdater();
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ?
      to.meta.middleware : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({
      ...context,
      next: nextMiddleware
    });
  }

  return next();
});

router.onError(error => {
  // console.error(error);
  // Vue.prototype.$log.error('Failure Reason: ', error.message, error);
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    // Vue.prototype.$log.error('Reloading Window 1');
    window.location.reload();
  }
  else if (/Loading.*chunk.*failed./i.test(error.message)) {
    // Vue.prototype.$log.error('Reloading Window 2');
    window.location.reload();
  }
});

export default router;
