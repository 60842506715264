var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"con-upload vx-upload"},[(_vm.viewActive)?_c('view-upload',{attrs:{"src":_vm.viewSrc}}):_vm._e(),(_vm.showInput)?_c('div',{staticClass:"con-img-upload"},[_vm._l((_vm.getFilesFilter),function(img,index){return _c('div',{key:index,staticClass:"img-upload",class:{
        'fileError':img.error,
        'removeItem':_vm.itemRemove.includes(index)
      }},[_c('button',{staticClass:"btn-x-file",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeFile(index)}}},[_c('i',{staticClass:"material-icons notranslate",attrs:{"translate":"no"}},[_vm._v("clear")])]),_c('button',{staticClass:"btn-upload-file on-progress ready-progress",class:{'loading-progress': (!img.success && ! img.error) },staticStyle:{"height":"100%"}},[_c('i',{staticClass:"material-icons notranslate",attrs:{"translate":"no"}},[_vm._v(" "+_vm._s(img.success ? _vm.completedIcon : (img.error ? 'cloud_off' : 'cloud_upload'))+" ")])]),(img.src)?_c('img',{key:index,style:({
          maxWidth:img.orientation == 'h'?'100%':'none',
          maxHeight:img.orientation == 'w'?'100%':'none'
        }),attrs:{"src":img.src,"alt":"img"},on:{"touchend":function($event){return _vm.viewImage(img.src,$event)},"click":function($event){return _vm.viewImage(img.src,$event)}}}):_vm._e(),(!img.src)?_c('h4',{staticClass:"text-archive"},[_c('i',{staticClass:"material-icons notranslate",attrs:{"translate":"no"}},[_vm._v("description")])]):_vm._e()])}),_c('div',{staticClass:"con-input-upload",class:{
        'on-progress-all-upload':_vm.percent != 0,
        'is-ready-all-upload':_vm.percent >= 100,
        'disabled-upload':_vm.$attrs.hasOwnProperty('disabled') || _vm.limit?(_vm.srcs.length - _vm.itemRemove.length) >= Number(_vm.limit):false
      }},[_c('input',_vm._b({ref:"fileInput",attrs:{"disabled":_vm.disabled,"accept":_vm.acceptExtensions,"type":"file"},on:{"change":_vm.getFiles}},'input',_vm.$attrs,false)),(!_vm.disabled)?_c('span',{staticClass:"text-input"},[_vm._v(_vm._s(_vm.textHint))]):_vm._e(),(_vm.disabled)?_c('span',{staticClass:"text-input"},[_vm._v("La carga está deshabilitada")]):_vm._e(),(!_vm.acceptAll && !_vm.disabled)?_c('span',{staticClass:"text-input small"},[_vm._v("( "+_vm._s(_vm.acceptHint)+" )")]):_vm._e()])],2):_vm._e(),(!_vm.showInput)?_c('div',{staticClass:"con-img-upload"},[_c('div',{staticClass:"img-upload"},[(!_vm.fileIsVerified && !_vm.disabled)?_c('button',{staticClass:"btn-x-file",attrs:{"type":"button"},on:{"click":function($event){return _vm.activateUpdateMode()}}},[_c('vx-tooltip',{attrs:{"title":"Cargar otro archivo","color":"primary","position":"left","text":"De click en el icono para poder cargar un nuevo documento"}},[_c('i',{staticClass:"material-icons notranslate",attrs:{"translate":"no"}},[_vm._v("clear")])])],1):_vm._e(),_c('button',{staticClass:"btn-upload-file on-progress ready-progress",class:{'rejection-progress': _vm.fileIsRejected },staticStyle:{"height":"100%"}}),_c('h4',{staticClass:"text-archive"},[_c('i',{staticClass:"material-icons notranslate",attrs:{"translate":"no"}},[_vm._v(_vm._s(_vm.loadedFileIcon))])])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }