export function setUser(state, user) {
  state.user = user
  state.isLogged = true
  state.error = false
  state.errorMessage = ''
  state.invalid = false
}

export function setRoleGeneralData(state, data) {
  state.roleGeneralData = data
}

export function updateInvestorBalancesData(state, data) {
  state.roleGeneralData.balance = data.balance
}

export function setStateAccessToken(state, token) {
  state.access_token = token;
}

export function setCurrentProgramData(state, program)
{
  state.currentProgram = program;
}

export function setCurrentGeolocationData(state, geolocation) {
  state.currentGeolocation = geolocation;
}

export function setSessionLocationRegistered(state, isRegistered) {
  state.sessionLocationRegistered = isRegistered;
}

export function setShowInvestorBalance(state, option) {
  state.showInvestorBalance = option;
}

export function authError(state, message) {
  state.errorMessage = message
  if (message === 'Invalid') {
      state.invalid = true
  } else {
      state.invalid = false
  }
  state.user = null
  state.isLogged = false
  state.error = true
  state.access_token = null
  state.currentProgram = null
}

export function user_role(state) {
  return state.user.role;
}

export function cleanSession(state) {
  state.user = null
  state.roleGeneralData = null
  state.isLogged = false
  state.access_token = null
  state.logged_at = null
  state.error = false
  state.invalid = false
  state.errorMessage = ''
  state.currentProgram = null
  state.currentGeolocation = null,
  state.sessionLocationRegistered = false
}
