import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from '@/router'
import store from '@/store/store'
import VueCookies from 'vue-cookies'

Vue.use(AclInstaller)

export default new AclCreate({
  initial: 'none',
  notfound: {
    path: '/error',
    forwardQueryParams: true,
  },
  router,
  acceptLocalRules: true,
  globalRules: {
    isSupplier: new AclRule('supplier').generate(),
    isAgent: new AclRule('supplier_agent').generate(),
    isSupplierOrAgent: new AclRule('supplier_agent').or('supplier').generate(),
    isApplicant: new AclRule('client').generate(),
    isInvestor: new AclRule('investor').generate(),
    isUser: new AclRule('supplier').or('supplier_agent').or('client').or('investor').or('program_administrator').or('program_executive_manager').or('program_commercial_manager').generate(),
    isPublic: new AclRule('supplier').or('supplier_agent').or('client').or('investor').or('program_administrator').or('program_executive_manager').or('program_commercial_manager').or('none').generate(),
    isAdmin: new AclRule('admin').generate(),
    isBackoffice: new AclRule('admin').generate(), // TODO
    isExecutiveManager: new AclRule('program_executive_manager').generate(),
    isExecutiveOrCommercialManager: new AclRule('program_executive_manager').or('program_commercial_manager').generate(),
    isProgramCommercialManager: new AclRule('program_commercial_manager').generate(),
    isProgramAdmin: new AclRule('program_administrator').generate(),
    isProgram: new AclRule('program_administrator').or('program_executive_manager').or('program_commercial_manager').generate(),
  },
  middleware: async acl => {
    const user = store.getters["auth/getUser"];
    const extended_role = store.getters["extended_auth/get_extended_session_role"];
    let role = null;
    if (user && user.role){
      role = user.role
    }
    else if(extended_role){
      role = extended_role;
    }

    if(role){
      acl.change(role);
    }
  }
})