export default {
  methods: {
    log(message){
      if (this.ShowRGConsoleLogs) {
        console.log(message);
      }
    },
    warn(message){
      if (this.ShowRGConsoleWarns) {
        console.warn(message);
      }
    },
    error(message){
      if (this.ShowRGConsoleErrors) {
        console.error(message);
      }
    }
  }
}