<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-big-title">
      <vx-card class="big-title-card">
        <h1 class="rg-big-title extrabold">
          <slot></slot>
        </h1>
      </vx-card>
    </div>
  </div>
</template>
<script>
export default {
  name: 'big-title'
}
</script>