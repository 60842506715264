export async function getUserInfo (context, role_name = null) {
    try {
        let params = "";
        if(role_name){
            params = `?role=${role_name}`;
        }
        let res = await axios.get(`/api/v1/users/getRgUserInfo${params}`);
        res.data["logged_at"] = Date.now();
        res.logged_at = Date.now();
        context.commit('setUser', res.data);

        if(res.data.role === 'investor'){
            // solicitar la informacion de nivel y saldo del inversionista
            await getInvestorGeneralData(context, res.data.investor_id);
        }else{
            if(res.data.role === 'client'){
                // solicitar la informacion de nivel y saldo del inversionista
                await getApplicantGeneralData(context, res.data.client_id);
            } else {
                if(res.data.role === 'supplier'){
                    await getSupplierGeneralData(context, res.data.supplier_id);
                }
            }
        }
        
        let sessionData = {
            date: Date.now()
        };

        context.commit('timer/registerNextCloseSession', sessionData, {root: true})
        return res;

    } catch(e) {
        context.commit("authError", e.response.data.message);
        throw e;
    }
}

export async function getInvestorGeneralData (context, investor_id) {
    try {
        let res = await axios.get(`/api/v2/investor/${investor_id}/getGeneralData`);
        context.commit('setRoleGeneralData', res.data);
        return res;

    } catch(e) {
        context.commit('setRoleGeneralData', null);
        throw e;
    }
}
export async function getApplicantGeneralData (context, applicant_id) {
    try {
        let res = await axios.get(`/api/v1/applicant/${applicant_id}/getGeneralData`);
        context.commit('setRoleGeneralData', res.data);
        return res;

    } catch(e) {
        context.commit('setRoleGeneralData', null);
        throw e;
    }
}

export async function getSupplierGeneralData (context, supplierId) {
    try {
        let res = await axios.get(`/api/v1/supplier/${supplierId}/getGeneralData`);
        context.commit('setRoleGeneralData', res.data);
        return res;

    } catch(e) {
        context.commit('setRoleGeneralData', null);
        throw e;
    }
}

export async function getInvestorBalances(context, investor_id){
    try {
        let res = await axios.get(`/api/v2/investor/${investor_id}/getBalancesData`);
        context.commit('updateInvestorBalancesData', res.data);
        return res;
    } catch(e) {
        context.commit('updateInvestorBalancesData', null);
        throw e;
    }
}

export async function logoutRequest(context, token) {
    try {
        await axios.post('/api/auth/logout', {
          device_id: this.$cookies.get('device_id')
        }, {user: {token: token}});
    } catch (e) {

    } finally {
        context.commit('cleanSession');
    }
}

export async function apiLogoutRequest(context, token) {
    try {
        await axios.post('/api/auth/logout', {
          device_id: this.$cookies.get('device_id')
        }, {user: {token: token}});
    } catch (e) {

    } finally {
        context.commit('cleanSession');
    }
}