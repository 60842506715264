<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-sub-title">
      <vx-card class="sub-title-card">
        <h1 class="rg-sub-title extrabold">
          <slot></slot>
        </h1>
      </vx-card>
    </div>
  </div>
</template>
<script>
export default {
  name: 'sub-title'
}
</script>