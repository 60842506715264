import store from '../store/store'

export default function in_process_auth({ next, router, to }) {
  if (store.getters["auth/getUser"] == null && store.getters["extended_auth/get_extended_session_token"] == null) {
    return router.replace({name: "login", query: {redirect: to.fullPath}});
    //window.location = process.env.VUE_APP_LOGOUT_PATH;
  }
  else {
    const u = store.getters["auth/getUser"];
    const ext_u = store.getters["extended_auth/get_extended_session_user"];
    let status = 0;
    if(u != null){
      status = u.supplier_status;
    }
    else if(ext_u != null){
      status = ext_u.supplier_status;
    }
    if(status < 3){
      if(u.supplier_onboarding_step == null) {
        return router.replace("inicio");
      }
    }
  }
  return next();
}