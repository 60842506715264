export default {
  user: null,
  roleGeneralData: null,
  isLogged: false,
  access_token: null,
  logged_at: null,
  error: false,
  invalid: false,
  errorMessage: '',
  currentProgram: null,
  currentGeolocation: null,
  sessionLocationRegistered: false,
  showInvestorBalance: false
}