export function set_new_extended_session(state, uid) {
  state.has_extended_session = true
  state.extended_session_uid = uid
}

export function set_extended_session_role(state, role) {
  state.extended_session_role = role
}

export function set_extended_session_token(state, token) {
  state.extended_access_token = token;
}

export function set_extended_session_user(state, user) {
  state.extended_session_user = user
}

export function extended_session_uid(state) {
  return state.extended_session_uid;
}

export function extended_session_role(state) {
  return state.extended_session_role;
}

export function cleanExtendedSession(state) {
  state.has_extended_session = false
  state.extended_session_uid = null
  state.extended_session_role = null
  state.extended_access_token = null
  state.extended_session_user = null
}
