import Vue from "vue";
import Vuex from "vuex";
import VuexPersistance from "vuex-persist";
import Crypto from "crypto-js";

import auth from '../modules/authentication'
import extended_auth from '../modules/extended_authentication'
import timer from '../modules/timer'
import efficientEnergySimulator from '../modules/efficient_energy_simulator'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

const useEncryption = (process.env.VUE_APP_USE_SECURE_STORAGE === "true" || process.env.VUE_APP_USE_SECURE_STORAGE === true);
const encryptionToken = process.env.VUE_APP_SECURE_STORAGE_TOKEN;

/*
const vuexSession = new VuexPersistance({
    storage: window.sessionStorage,
    modules: ['auth', 'timer']
})

const vuexLocal = new VuexPersistance({
  storage: window.localStorage,
  modules: ['extended_auth']
})
*/

function storageGetItem(key, storage){
    const store = storage.getItem(key);
    if(store){
        try {
            if(useEncryption === true){
                const byts = Crypto.AES.decrypt(store, encryptionToken);
                return JSON.parse(byts.toString(Crypto.enc.Utf8));
            }
            else {
                return store;
            }
        } catch (e) {
            storage.removeItem(key)
        }
    }
    return null;
}

function storageSetItem(key, value, storage){
    if(useEncryption === true){
        const store = Crypto.AES.encrypt(value, encryptionToken).toString();
        return storage.setItem(key, store);
    }
    else {
        return storage.setItem(key, value);
    }
}

function storageRestoreState(key, storage){
    const value = storage.getItem(key)
    if(value){
        try {
            if(useEncryption === true){
                const byts = Crypto.AES.decrypt(value, encryptionToken);
                return JSON.parse(byts.toString(Crypto.enc.Utf8));
            }
            else {
                if (typeof value === 'string') {// If string, parse, or else, just return
                    return (
                      JSON.parse(value || '{}')
                    )
                } else {
                    return (value || {})
                }
            }
        } catch (e) {
            storage.removeItem(key)
        }
    }
    return {};
}

const vuexSession = new VuexPersistance({
    storage: {
        getItem(key) {
            return storageGetItem(key, window.sessionStorage);
        },
        setItem(key, value) {
            return storageSetItem(key, value, window.sessionStorage);
        },
        removeItem(key) {
            window.sessionStorage.removeItem(key);
        },
    },
   restoreState(key, storage){
        return storageRestoreState(key, window.sessionStorage);
    },
    modules: ['auth', 'timer']
})

const vuexLocal = new VuexPersistance({
    storage: {
        getItem(key) {
            return storageGetItem(key, window.localStorage);
        },
        setItem(key, value) {
            return storageSetItem(key, value, window.localStorage);
        },
        removeItem(key) {
            window.localStorage.removeItem(key);
        }
    },
    restoreState(key, storage){
        return storageRestoreState(key, window.localStorage);
    },
    modules: ['extended_auth']
})

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
        auth,
        extended_auth,
        timer,
        efficientEnergySimulator
    },
    plugins: [vuexSession.plugin, vuexLocal.plugin]
})
