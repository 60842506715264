export function getUser(state) {
  return state.user;
}

export function getRoleGeneralData(state) {
  return state.roleGeneralData;
}

export function getIfUserIsInvestor(state) {
  if(state.user){
    return state.user.role === "investor";
  }
  return false;
}

export function getInvestorId(state) {
  if(state.user){
    return state.user.investor_id;
  }
  return null;
}