<!-- =========================================================================================
    File Name: RgRadio.vue
    Description: custom component for Radio Button RedGirasol style
========================================================================================== -->
<template>
  <div>
      <input
        type="radio"
        :checked="isChecked"
        :value="value"
        :id="idUnique"
        :disabled="isDisabled"
        @change="$emit('change', $event.target.value)"
      />
      <label class="w-full" :class="getClasses" :for="idUnique">
        {{ label }} 
        <template  v-if="desc != null">
          <br>
          <span v-html="desc" class="desc"></span>
        </template>
      </label>
  </div>
</template>

<script>
export default {
  name:'rg-radio',
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    label: { type: String, default: "", required: true },
    groupName: { type: String, default: "", required: true },
    modelValue: { default: null },
    value: { default: ""},
    desc: { type: String, default: "", required: false, default: null },
    isDisabled: { type: Boolean, default: false }
  },
  computed: {
    isChecked() {
      return this.modelValue == this.value;
    },
    idUnique() {
      return this.groupName+"_"+this.value;
    },
    getClasses() {
      let classes = [];
      if (this.desc != null) {
        classes.push("text-start");
      }

      if (this.isDisabled) {
        classes.push("disabled");
      }

      return classes.join(" ");
    }
  },
};
</script>

<style scoped>
input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + label {
  background-color: #D1F1C0;
  color: #096600;
  border-color: #28DE18;
}

label {
  display: inline-block;
  padding: 7px 5px;
  font-size: 13px;
  text-align: center;
  color: black;
  background-color: white;
  border-radius: 10px;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  margin-right: 1em;
  cursor: pointer;
}

label:hover {
  background-color: #D1F1C0;
  color: #096600;
  border-color: #28DE18;
}

.account-button label {
    border: 2px solid #28DE18 !important;
    background-color: #fff !important;
    font-weight: 900 !important;
    padding: 2rem !important;
    border-radius: 5px !important;
}

  .account-button label:hover {
      color: #2c2c2c !important;
      background-color: #28de181f !important;
  }

  .account-button input[type="radio"]:checked+label {
      background-color: #28de18 !important;
      color: #FFF;
  }

  .account-button .disabled {
    border: 2px solid #f3f3f3 !important;
  }
    .account-button .disabled:hover {
      background-color: transparent !important;
    }

@media (max-width: 992px) {
      .account-type-selector {
        margin-left: 10%;
        margin-right: 10%;
    }
}


.desc {
  font-size: smaller;
  font-weight: 100;
}

.text-start {
  text-align: start;
}
</style>
