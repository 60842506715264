<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vs-button type="line" color="success" icon="cloud_download" class="mb-4 p-1 download-btn" @click="click" >{{title}}</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "download-button",
  props: {
    id: String,
    title: String,
    actionLink: String,
    remoteFile: Boolean,
    fileName: String,
  },
  computed: {
    actionUrl: function() {
      if(this.remoteFile == true){
        return this.StorageURL + this.actionLink;
      }
      else {
        return this.ApiDomain + this.actionLink;
      }
    }
  },
  methods: {
    click: function() {
      window.open(this.actionUrl, "_blank");      
    }
  }
};
</script>
